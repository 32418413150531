import { Box, Typography} from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const MessageDiscountResponde = ({respondeMessage}) => {

    return (
        <Box>
            { respondeMessage && (
                <Box>
            <Typography
                sx={{
                    ...theme.paragraph.p2,
                    mb: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <LocalOfferIcon sx={{ color: '#e10098', fontSize: '24px' }} />
                {respondeMessage.Description}
            </Typography>
            <Typography
            sx={{ ...theme.fontType.title10, color:'black' }}
            >
                {respondeMessage.Value}% descuento
            </Typography>
            </Box>
        )}
        </Box>
    );
};

export default MessageDiscountResponde;