import { createTheme } from '@mui/material/styles';

export const shades = {
    primary: {
        100: "#cccccc",
        200: "#999999",
        300: "#666666",
        400: "#333333",
        500: "#000000",
        600: "#000000",
        700: "#000000",
        800: "#000000",
        900: "#000000"
    },
    secondary: {
        100: "#f7ccd2",
        200: "#ef99a4",
        300: "#e66677",
        400: "#de3349",
        500: "#d6001c",
        600: "#ab0016",
        700: "#800011",
        800: "#56000b",
        900: "#2b0006"
    },
    neutral: {
        100: "#f5f5f5",
        200: "#ecebeb",
        300: "#e2e1e1",
        400: "#d9d7d7",
        500: "#cfcdcd",
        600: "#a6a4a4",
        700: "#7c7b7b",
        800: "#535252",
        900: "#292929"
    },
}

export const theme = createTheme({
    palette: {
        primary: {
            main: shades.primary[500]
        },
        secondary: {
            main: shades.secondary[500]
        },
        neutral: {
            dark: shades.neutral[700],
            main: shades.neutral[500],
            light: shades.neutral[100]
        }
    },
    typography: {
        fontFamily: ["Helvetica Neue", "Helvetica", "Verdana", "Arial", "Helvetica", "sans-serif"].join(","),
        fontSize: 11,
        color: "#666",
        h1: {
            fontFamily: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(","),
            fontSize: 48,
        },
        h2: {
            fontFamily: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(","),
            fontSize: 36,
        },
        h3: {
            fontFamily: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(","),
            fontSize: 20,
        },
        h4: {
            fontFamily: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(","),
            fontSize: 14,
        },
        h5: {
            fontFamily: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(","),
            fontSize: 12,
        },
    },
    error: {
        e1: {
            backgroundColor: '#ffffff',
            padding: '10px',
            border: 'solid 1px red',
            mt: 2, fontWeight: "bold"
        }
    },
    fontType: {
        miniTitle: {
            fontSize: '.9em',
            fontWeight: 'normal',
            color: '#666',
            m: '10px',
        },
        title1: {
            fontSize: '1em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        title2: {
            fontSize: '1.2em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        title3: {
            fontSize: '1.3em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        title4: {
            fontSize: '1.4em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        title5: {
            fontSize: '1.5em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        title6: {
            fontSize: '1.6em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        title7: {
            fontSize: '1.7em',
            fontWeight: 'bold',
            color: '#4c4c4c',
            m: '10px',
        },
        title8: {
            fontSize: '1.8em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        title9: {
            fontSize: '1.9em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        title10: {
            fontSize: '2em',
            fontWeight: 'bold',
            color: '#666',
            m: '10px',
        },
        price: {
            fontSize: '1.2em',
            fontWeight: 'normal',
            color: 'black',
            m: '10px',
        },
        price1: {
            fontSize: '1.4em',
            fontWeight: 'bold',
            color: 'red',
            m: '10px',
        }
    },
    paragraph: {
        p: {
            fontSize: '1em',
            fontWeight: 'normal',
            color: '#666',
            m: '10px',
            textAlign: 'justify',
        },
        p1: {
            fontSize: '1.1em',
            fontWeight: 'normal',
            color: '#666',
            m: '10px',
            textAlign: 'justify',
        },
    },
    box: {
        b1: {
            backgroundColor: '#fff',
            p: '15px',
        },
        loginPrompt: {
            backgroundColor: '#fff',
            width: '400px',
            p: '20px',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "#c4c4c4",
                        },
                        "&:hover fieldset": {
                            borderColor: "#c4c4c4",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#c4c4c4",
                        },
                    },
                    "& .MuiInputLabel-root": {
                        color: "#e10098",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                        color: "#e10098",
                    },
                    "& .MuiInputLabel-root": {
                        fontSize: ".9rem", // Tamaño del label normal
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                        fontSize: ".9rem", // Tamaño del label cuando está enfocado
                    },
                    "& .MuiInputLabel-root": {
                        fontSize: "1rem",
                        backgroundColor: "#fff", // Fondo del label
                        padding: "0 5px", // Añadir padding para que el fondo del label no se corte
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                        backgroundColor: "#fff", // Fondo del label cuando está enfocado
                    },
                },
            },
        },
    },
    buttons: {
        b1: {
            backgroundColor: "#e10098", // Color de fondo rosa principal
            color: "#fff", // Color del texto
            textTransform: "none", // Mantener las mayúsculas y minúsculas
            fontWeight: "bold", // Estilo de texto en negrita
            padding: "10px 20px", // Espaciado interno
            borderRadius: "5px", // Bordes redondeados
            fontSize: "14px", // Tamaño del texto
            "&:hover": {
                backgroundColor: "#cc007a", // Color de fondo al pasar el mouse
            },
            "&:active": {
                backgroundColor: "#b00068", // Color de fondo al hacer clic
            },
            transition: "all 0.3s ease", // Suavizar las transiciones
        },
        b2: {
            backgroundColor: "#fff", // Color de fondo rosa principal
            color: "#e10098", // Color del texto
            textTransform: "none", // Mantener las mayúsculas y minúsculas
            fontWeight: "none", // Estilo de texto en negrita
            padding: "10px 20px", // Espaciado interno
            borderRadius: "5px", // Bordes redondeados
            fontSize: "14px", // Tamaño del texto
            border: 'solid 2px #e10098',
            "&:hover": {
                backgroundColor: "#f6f6f6", // Color de fondo al pasar el mouse
            },
            "&:active": {
                backgroundColor: "#f6f6f6", // Color de fondo al hacer clic
            },
            transition: "all 0.3s ease", // Suavizar las transiciones
        }
    }
});