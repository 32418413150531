import React, { useEffect } from 'react';

const StatusScreenBrick = ({ paymentId }) => {
  const publicKey = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY;
  const mp = new window.MercadoPago(publicKey, { locale: 'es-MX' });
  const bricksBuilder = mp.bricks();

  // Mueve renderStatusScreenBrick para que esté en el alcance del componente
  const renderStatusScreenBrick = async () => {
    const settings = {
      initialization: {
        paymentId: paymentId, // Identificador del pago a verificar
      },
      customization: {
        visual: {
          hideStatusDetails: true,
          hideTransactionDate: true,
          style: {
            theme: 'default', // 'default' | 'dark' | 'bootstrap' | 'flat'
          },
        },
        backUrls: {
          error: 'https://api.ternurareal.mx/error',
          return: 'https://api.ternurareal.mx/account',
        },
      },
      callbacks: {
        onReady: () => {
        },
        onError: (error) => {
          
        },
      },
    };
    await bricksBuilder.create('statusScreen', 'statusScreenBrick_container', settings);
  };

  const loadMercadoPago = () => {
    if (!window.MercadoPago) {
      const script = document.createElement('script');
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.onload = renderStatusScreenBrick;
      document.body.appendChild(script);
    } else {
      renderStatusScreenBrick();
    }
  };

  useEffect(() => {
    loadMercadoPago();
  }, [paymentId]);

  return (
    <div>
      <div id="statusScreenBrick_container"></div>
      <button onClick={renderStatusScreenBrick}>Recargar Estado de Pago</button>
    </div>
  );
};

export default StatusScreenBrick;
