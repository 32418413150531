import { Button } from "@mui/material";
import { theme } from "../../theme";

const SignupButtomPrompt = ({ handleSubmit }) => {
    return (
        <Button
            variant="contained"
            sx={{
                ...theme.buttons.b1,
                m: '20px 0px',
                width: '100%',
            }}
            onClick={handleSubmit} // Ejecutar la función handleSubmit al hacer clic
        >
            Crear cuenta
        </Button>
    );
};

export default SignupButtomPrompt;
