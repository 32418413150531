import { useEffect, useState } from "react";
import validateStock from "./validateStock"; // Importamos la función regular

const useCartStockInfo = (cart) => {
  const [stockInfo, setStockInfo] = useState({});

  useEffect(() => {
    const fetchStockInfo = async () => {
      const newStockInfo = {};

      for (const item of cart) {
        // Llamamos a la función regular `validateStock`
        const { availableStock, stockErrorMessage } = await validateStock(item.idInventory, item.count + 1, item.count);
        newStockInfo[item.uniqueId] = {availableStock, stockErrorMessage};
      }

      setStockInfo(newStockInfo);
    };

    fetchStockInfo();
  }, [cart]);

  return stockInfo;
};

export default useCartStockInfo;
