import { createSlice } from '@reduxjs/toolkit';

// Resumen del proceso de checkout
const checkoutResume = {
    totalItems: null, // Total de productos en el carrito
    totalPrice: null, // Precio total (Incluye descuentos individuales, promociones y gastos de envío)
    subtotal: null, // Precio subtotal (Incluye descuentos individuales sin gastos de envío ni promociones)
    averagePrice: null, // Precio promedio de cada item (totalPrice/totalItems) 
    promoDiscountAmount: null, // Cantidad de descuento en base a la promocion definida en el código (por porcentaje o por precio fijo)
    promoCode: null, // Código del cupón aplicado
};

// Control del carrito de compras
const initialState = {
    isCartOpen: false,
    cart: [],
    items: [],
    checkoutResume,
};

export const cartReducer = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },

        addToCart: (state, action) => {
            const { idInventory, talla, count, shippingCost } = action.payload.item;
            const uniqueId = `${idInventory}`;

            const itemInCart = state.cart.find((item) => item.uniqueId === uniqueId);

            if (itemInCart) {
                itemInCart.count += count;
            } else {
                state.cart = [...state.cart, { ...action.payload.item, uniqueId, talla }];
            }

            // Actualizar el resumen del checkout
            cartReducer.caseReducers.applyResumeCheckout(state, { payload: { shippingCost } });
        },

        removeFromCart: (state, action) => {
            const { uniqueId, shippingCost } = action.payload;
            state.cart = state.cart.filter((item) => item.uniqueId !== uniqueId);

            // Actualizar el resumen del checkout
            cartReducer.caseReducers.applyResumeCheckout(state, { payload: { shippingCost } });
        },

        increaseCount: (state, action) => {
            const { shippingCost } = action.payload;

            state.cart = state.cart.map((item) => {
                if (item.uniqueId === action.payload.uniqueId) {
                    item.count++;
                }
                return item;
            });

            // Actualizar el resumen del checkout
            cartReducer.caseReducers.applyResumeCheckout(state, { payload: { shippingCost } });
        },

        decreaseCount: (state, action) => {
            const { shippingCost } = action.payload;

            state.cart = state.cart.map((item) => {
                if (item.uniqueId === action.payload.uniqueId && item.count > 1) {
                    item.count--;
                }
                return item;
            });

            // Actualizar el resumen del checkout
            cartReducer.caseReducers.applyResumeCheckout(state, { payload: { shippingCost } });
        },

        setIsCartOpen: (state) => {
            state.isCartOpen = !state.isCartOpen;
        },

        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload;
        },

        setPromoDiscountAmount: (state, action) => {
            let { promoDiscountAmount } = action.payload || 0;
            state.checkoutResume.promoDiscountAmount = promoDiscountAmount
        },

        applyDiscount: (state, action) => {
            const { promoDiscountAmount, promoCode, shippingCost } = action.payload;
            console.log("Discount: " + promoDiscountAmount + " - " + promoCode);
            state.checkoutResume.promoCode = promoCode;
            state.checkoutResume.promoDiscountAmount = promoDiscountAmount;

            // Actualizar el resumen del checkout
            cartReducer.caseReducers.applyResumeCheckout(state, { payload: { shippingCost } });
        },

        applyResumeCheckout: (state, action) => {
            let { shippingCost } = action.payload || 0;
            shippingCost = shippingCost != undefined?shippingCost:0
            console.log("shippingCost: " + shippingCost);
            // Actualizamos totalItems
            state.checkoutResume.totalItems = state.cart.reduce((total, item) => total + item.count, 0);

            // Calculamos el totalPrice aplicando descuentos individuales
            let totalPrice = state.cart.reduce((totalPrice, item) => {
                const discountedPercent = item?.attributes?.discount || 0;
                const discountedPrice = item?.attributes?.price * (1 - discountedPercent / 100);
                return totalPrice + discountedPrice * item.count;
            }, 0);

            // Aplicamos el subtotal unicamente con los precios de los productos para aplicarles descuento.
            state.checkoutResume.subtotal = totalPrice;

            // Aplicamos el descuento promocional
            const promoDiscountAmount = state.checkoutResume.promoDiscountAmount || 0;
            totalPrice -= promoDiscountAmount;

            // Aplicamos el costo de envío si es aplicable
            totalPrice += shippingCost;

            // Guardamos el totalPrice y el precio promedio
            state.checkoutResume.totalPrice = totalPrice;
            state.checkoutResume.averagePrice = state.checkoutResume.totalItems > 0 ? totalPrice / state.checkoutResume.totalItems : 0;
        },

        clearResumeCheckout: (state) => {
            state.checkoutResume.totalItems = null;
            state.checkoutResume.totalPrice = null;
            state.checkoutResume.subtotal = null;
            state.checkoutResume.averagePrice = null;
            state.checkoutResume.promoDiscountAmount = null;
            state.checkoutResume.promoCode = null;
        },

        clearCart: (state) => {
            state.cart = []; // Limpia todos los productos del carrito
            cartReducer.caseReducers.clearResumeCheckout(state); // Limpia el resumen del checkout
        },
    }
});

export const {
    setItems,
    addToCart,
    removeFromCart,
    increaseCount,
    decreaseCount,
    setIsCartOpen,
    setCurrentCategory,
    setPromoDiscountAmount,
    applyDiscount,
    applyResumeCheckout,
    clearResumeCheckout,
    clearCart,
} = cartReducer.actions;

export default cartReducer.reducer;
