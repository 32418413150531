import { useEffect, useState } from "react";
import { initGA, logPageView } from "./utils/analytics"; // Ajusta el path según tu estructura

import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Checkout from "./scenes/checkout/Checkout";
import Home from "./scenes/home/Home";
import ItemDetails from "./scenes/itemDetails/ItemDetails";
import Confirmation from "./scenes/checkout/Confirmation";
import Navbar from "./scenes/global/Navbar";
import Cart from "./scenes/account/CartMenu";
import Footer from "./scenes/global/Footer";
import Rules from "./scenes/global/Rules";
import Test from "./scenes/global/Test";
import LoginPrompt from "./scenes/auth/LoginPrompt";
import Signup from "./scenes/auth/Signup";
import { useDispatch } from "react-redux";
import { setUser, logoutUser } from "./state/userReducer"; // Importar acciones de Redux
import { initMercadoPago } from '@mercadopago/sdk-react';
import CheckoutForm from "./components/CheckoutFormMP";
import Loading from './components/Loading';

initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    initGA(); // Inicializar GA cuando la aplicación cargue
    logPageView(); // Registrar la primera visita a una página
  }, []);

  useEffect(() => {
    logPageView(); // Registrar cada cambio de página
  }, [pathname]);

  return null;
};

function App() {
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState(null);


  // Verificar el estado de autenticación al cargar la aplicación
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("user"));

    if (token && userData) {
      // Si hay token y datos de usuario en localStorage, establecer en Redux
      dispatch(setUser({ user: userData }));
    } else {
      // Si no hay datos, desloguear usuario
      dispatch(logoutUser());
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchActiveCampaign = async () => {
      try {
        const response = await fetch('https://api.ternurareal.mx/api/campaign'); // Llama al endpoint en el backend
        if (!response.ok) {
          throw new Error('Error al cargar la campaña activa');
        }
        
        const data = await response.json();
        setCampaign(data.activeCampaign); // Solo guarda los datos de la campaña activa
      } catch (error) {
        console.error("Error fetching active campaign:", error.message);
      }
    };

    fetchActiveCampaign();
  }, []);

  if (!campaign) {
    return <Loading />;
  }

  return (
    <div className="app">
      <meta name="keywords" content={campaign.keywords.join(", ")} />
      <meta name="description" content={campaign.metaDescription} />
      <BrowserRouter>
        <Navbar></Navbar>
        <ScrollToTop></ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="item/:itemId" element={<ItemDetails />}></Route>
          <Route path="checkout" element={<Checkout />}></Route>
          <Route path="checkout/success" element={<Confirmation />}></Route>
          <Route path="cart" element={<Cart />}></Route>
          <Route path="rules" element={<Rules />}></Route>
          <Route path="test" element={<Test />}></Route>
          <Route path="login" element={<LoginPrompt />}></Route>
          <Route path="signup" element={<Signup />}></Route>
          <Route path="checkoutForm" element={<CheckoutForm />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
