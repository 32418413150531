import React from 'react';

const Model3DViewer = () => {
  return (
    <div>
      <h1>Producto en 3D</h1>
      <model-viewer
        src="/models/dona2.glb"
        ar
        ar-modes="scene-viewer webxr quick-look"
        environment-image="neutral"
        camera-controls
        auto-rotate
        alt="Descripción del producto en 3D"
        style={{ width: '100%', height: '500px' }}
      >
        <button slot="ar-button" className="ar-button">
          Ver en realidad aumentada
        </button>
      </model-viewer>
    </div>
  );
};

export default Model3DViewer;