import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Divider, IconButton, Typography, Paper, useMediaQuery, Link, Modal, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import CloseIcon from '@mui/icons-material/Close';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MessageDiscountResponde from "./MessageDiscountResponde";
import { applyDiscount, applyResumeCheckout, setPromoDiscountAmount } from './../../state/cartReducer';

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart.cart);
    const checkoutResume = useSelector((state) => state.cart.checkoutResume); // Obtenemos los datos actualizados del checkoutResume.
    const shippingCost = useSelector((state) => state.shipping.shippingCost); // Obtener el costo de envío desde Redux

    const [promoCodeModalOpen, setPromoCodeModalOpen] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [validationMessage, setValidationMessage] = useState(null); // Para mostrar mensajes de éxito o error
    const [respondeMessage, setRespondeMessage] = useState(null); // Para guardar la respuesta del codigo de promoción
    const promoInputRef = useRef(null);

    dispatch(applyResumeCheckout({ shippingCost }));

    // Detectar si es móvil
    const isMobile = useMediaQuery("(max-width:600px)");

    const handleNavigateToCheckout = () => {
        ReactGA.event({
            category: 'Compra',
            action: 'CompletarCompra',
            label: ''
        });
        navigate("/checkout");
    };

    const handlePromoCodeOpen = () => {
        setPromoCodeModalOpen(true);
    };

    const handlePromoCodeClose = () => {
        setPromoCodeModalOpen(false);
        setShowCloseButton(false);
        setPromoCode("");
        setValidationMessage(null);
    };

    // Función para validar el código de promoción
    const validatePromoCode = async () => {
        try {
            const response = await fetch('https://api.ternurareal.mx/api/discount/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: promoCode, totalPrice: checkoutResume.subtotal }),
            });

            const result = await response.json();
            if (response.ok && result.message === "Código válido") {
                setValidationMessage("¡Código válido!");
                const promoDiscountAmount = result.discountAmount; // Asegúrate de obtener el valor correcto
                setRespondeMessage(result);
                setShowCloseButton(true);

                // Actualizar el estado del carrito con el descuento
                dispatch(applyDiscount({ promoDiscountAmount, promoCode, shippingCost }));
            } else {
                setValidationMessage(result.error?.message || "Error al aplicar el código.");
                setRespondeMessage(null);
            }
        } catch (error) {
            console.error("Error al validar el código de promoción:", error);
            setValidationMessage("Error al conectar con el servidor. Intenta nuevamente.");
            setRespondeMessage(null);
        }
    };

    const handleApplyPromoCode = () => {
        validatePromoCode();
    };

    useEffect(() => {
        if (promoCodeModalOpen && promoInputRef.current) {
            promoInputRef.current.focus(); // Asignar el foco cuando el modal esté abierto y el ref esté disponible
        }
    }, [promoCodeModalOpen]);

    const IVA = 0.00;

    return (
        <Box flex="3" width={isMobile ? "100%" : "auto"}>
            <Paper sx={{ p: "20px", backgroundColor: "#fff" }}>
                <FlexBox>
                    <Typography
                        sx={{
                            ...theme.fontType.title3,
                            textAlign: 'left',
                            letterSpacing: '0.5px',
                            mb: 3,
                            color: 'black'
                        }}
                    >
                        Resumen del pedido
                    </Typography>

                    <Link
                        onClick={handlePromoCodeOpen}
                        sx={{
                            ...theme.fontType.title2,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#e10098',
                            ml: '10px',
                            display: 'flex',               // Alineación en línea
                            alignItems: 'center',          // Centrado vertical
                            '&:hover': {
                                color: '#ff66b2'
                            },
                        }}
                    >
                        <LocalOfferIcon sx={{ color: '#e10098', fontSize: '24px', mr: '5px' }} /> {/* Espacio entre el icono y el texto */}
                        Tengo cupón de promoción
                    </Link>
                </FlexBox>

                {cart.length > 0 ? (
                    cart.map((item) => {

                        const price = item?.attributes?.price;
                        const discountedPercent = item?.attributes?.discount == null ? 0 : item?.attributes?.discount;
                        const discountedPrice = Math.round(price * (1 - (discountedPercent) / 100)); // Calcula el precio con descuento
                        return (
                            <Box key={`${item.uniqueId}-${item.talla}`} mb={0}> {/* Usamos uniqueId aquí */}
                                <FlexBox
                                    alignItems="flex-start"
                                    flexDirection={isMobile ? "column" : "column"}
                                    sx={{ p: "5px 0px 5px 0px" }}
                                >
                                    <FlexBox flex="1 1 100%" display="flex" alignItems="flex-start" flexDirection="row"
                                        sx={{
                                            color: '#000',
                                            textAlign: 'left',
                                            width: "100%"
                                        }}>
                                    </FlexBox>
                                    <FlexBox
                                        flex="1 1 100%"
                                        flexDirection={isMobile ? "row" : "row"}
                                        justifyContent="space-between"
                                        mt={isMobile ? 0 : 0}
                                        mb={isMobile ? 0 : 0}
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography
                                            sx={{ ...theme.fontType.title1, fontWeight: 'normal', m: '0px 10px 0px 5px', textAlign: 'left' }}
                                        >{item.attributes?.name || "Producto desconocido"}
                                            {" ("}
                                            {item.talla ? "T: " + item.talla + " / " : ""}
                                            Cant: {item.count ? item.count : 1}
                                            {")"}
                                        </Typography>
                                        {/* Precio original tachado y precio con descuento */}
                                        {discountedPercent > 0 ? (
                                            <Box display='flex' textAlign="center" sx={{ flexDirection: 'row' }}>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        textDecoration: "line-through",
                                                        color: "gray",
                                                        mr: 1,
                                                    }}
                                                >
                                                    {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format((item.count != null ? item.count : 1) * (price || 0))}
                                                </Typography>
                                                <Typography variant="h5" color="red" fontWeight="bold">
                                                    {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format((item.count != null ? item.count : 1) * (discountedPrice || 0))}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box display='flex' textAlign="center">
                                                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', ml: 1 }}>
                                                    {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format((item.count != null ? item.count : 1) * (discountedPrice || 0))}

                                                </Typography></Box>
                                        )}
                                    </FlexBox>
                                </FlexBox>
                            </Box>
                        );
                    })
                ) : (
                    <Typography variant="h6" textAlign="center" mt={4}>
                        Tu carrito está vacío.
                    </Typography>
                )}
                {cart.length > 0 && (
                    <Box>
                        <Divider sx={{ mb: "15px" }}></Divider>
                        <FlexBox mb="0px">
                            <Typography
                                sx={{
                                    fontSize: '1.1em', fontWeight: 'normal', m: '0px', color: '#000', display: 'flex',               // Alineación en línea
                                    alignItems: 'center'
                                }}>Subtotal:</Typography>
                            <Typography
                                sx={{
                                    fontSize: '1.1em', fontWeight: 'bold', m: '0px', color: '#000', display: 'flex',               // Alineación en línea
                                    alignItems: 'center'
                                }}>
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(checkoutResume.subtotal)}
                            </Typography>
                        </FlexBox>

                        {checkoutResume.promoDiscountAmount && (
                            <FlexBox mb="0px">
                                <Typography sx={{
                                    fontSize: '1.1em', fontWeight: 'normal', m: '0px', color: '#000', display: 'flex',               // Alineación en línea
                                    alignItems: 'center'
                                }}>
                                    Descuento por cupón:  {/* Espacio entre el icono y el texto */}</Typography>
                                <Typography sx={{
                                    fontSize: '1.1em', fontWeight: 'bold', m: '0px', color: 'red', display: 'flex',               // Alineación en línea
                                    alignItems: 'center'
                                }}>
                                    <LocalOfferIcon sx={{ color: '#e10098', fontSize: '24px', mr: '5px' }} />
                                    -{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(checkoutResume.promoDiscountAmount || 0)}
                                </Typography>
                            </FlexBox>
                        )}

                        <FlexBox mb="0px">
                            <Typography
                                sx={{
                                    fontSize: '1.1em', fontWeight: 'normal', m: '0px', color: '#000', display: 'flex',               // Alineación en línea
                                    alignItems: 'center'
                                }}
                            >Iva incluido:</Typography>
                            <Typography
                                sx={{
                                    fontSize: '1.1em', fontWeight: 'bold', m: '0px', color: '#000', display: 'flex',               // Alineación en línea
                                    alignItems: 'center'
                                }}
                            >
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(IVA)}
                            </Typography>
                        </FlexBox>
                        <FlexBox mb="0px">
                            <Typography
                                sx={{
                                    fontSize: '1.1em', fontWeight: 'normal', m: '0px', color: '#000', display: 'flex',               // Alineación en línea
                                    alignItems: 'center'
                                }}
                            >Gastos de envío:</Typography>
                            {shippingCost === undefined || shippingCost === null ? (
                                <Typography
                                    sx={{
                                        fontSize: '1.1em', fontWeight: 'normal', m: '0px', color: '#000', display: 'flex',               // Alineación en línea
                                        alignItems: 'center'
                                    }}>
                                    No incluido
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '1.2em', fontWeight: 'bold', m: '0px', color: '#000', display: 'flex',               // Alineación en línea
                                        alignItems: 'center'
                                    }}>
                                    {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(shippingCost)}
                                </Typography>
                            )}
                        </FlexBox>
                        <Divider />
                        <FlexBox m="15px 0px 0px 0px">
                            <Typography sx={{ ...theme.fontType.title4, color: 'black' }}>Total (IVA incluido):</Typography>
                            <Typography sx={{ ...theme.fontType.title4, color: 'black' }}>
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(checkoutResume.totalPrice || 0)}
                            </Typography>
                        </FlexBox>
                    </Box>
                )}

            </Paper>

            {/* Modal para aplicar código de promoción */}
            <Modal
                open={promoCodeModalOpen}
                onClose={() => { }} // No se cierra al hacer clic fuera del área
                aria-labelledby="promo-code-modal-title"
                aria-describedby="promo-code-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '400px',
                        bgcolor: '#fff',
                        borderRadius: 2,
                        p: 4,
                        boxShadow: 24,
                        textAlign: 'center',
                    }}
                >
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={handlePromoCodeClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="promo-code-modal-title" variant="h6" sx={{ mb: 2 }}>
                        Aplicar código de promoción
                    </Typography>
                    <TextField
                        fullWidth
                        label="Código de promoción"
                        variant="outlined"
                        value={promoCode}
                        inputRef={promoInputRef}
                        onChange={(e) => setPromoCode(e.target.value)}
                        sx={{ mb: 3 }}
                    />

                    <MessageDiscountResponde respondeMessage={respondeMessage?.discount} />

                    {validationMessage && (
                        <Typography
                            sx={{
                                ...theme.paragraph.p2,
                                mb: '20px',
                                color: validationMessage.includes('Código válido') ? 'green' : 'red',
                            }}
                        >
                            {validationMessage}
                        </Typography>
                    )}

                    <Button
                        onClick={handleApplyPromoCode}
                        sx={{
                            ...theme.buttons.b1,
                            width: '100%'
                        }}
                    >
                        Aplicar
                    </Button>
                    {showCloseButton && (
                        <Button
                            sx={{
                                ...theme.buttons.b2,
                                mt: '10px',
                                width: '100%',
                            }}
                            onClick={handlePromoCodeClose}
                        >
                            Cerrar
                        </Button>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default CartDetails;
