import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { shades } from "../../theme";

const importAll = (r) =>
    r.keys().reduce((acc, item) => {
        acc[item.replace("./", "")] = r(item);
        return acc;
    }, {});

const heroTextureImports = importAll(
    require.context("../../assets/carousel/", false, /\.(png|jpe?g|svg|avif|webp)$/)
);

const MainCarousel = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    return (
        <Box
            sx={{
                mt: isNonMobile ? "100px" : "60px",
                width: "100%",
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: isNonMobile ? "80%" : "100%",
                    overflow: "hidden",
                }}
            >
                <Carousel
                    infiniteLoop
                    showThumbs={false}
                    showIndicators
                    showStatus={false}
                    style={{ width: "100%", height: "100%" }}
                    renderArrowPrev={(onClickHandler, hasPrev, label) => (
                        <IconButton
                            onClick={onClickHandler}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: '0',
                                color: "white",
                                padding: "5px",
                                zIndex: "10",
                            }}
                        >
                            <NavigateBeforeIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                    )}
                    renderArrowNext={(onClickHandler, hasNext, label) => (
                        <IconButton
                            onClick={onClickHandler}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                right: '0',
                                color: "white",
                                padding: "5px",
                                zIndex: "10",
                            }}
                        >
                            <NavigateNextIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                    )}
                >
                    {Object.values(heroTextureImports).map((texture, index) => (
                        <Box key={`carousel-image-${index}`} sx={{ display: 'block' }}>
                            <img
                                src={texture}
                                alt={`carousel-${index}`}
                                style={{
                                    width: '100%',
                                    height: "auto",
                                    backgroundColor: 'white'
                                }}
                            />
                            <Box
                                color="white"
                                borderRadius="1px"
                                textAlign="left"
                                backgroundColor="rgb(0,0,0,0.6)"
                                position="absolute"
                                sx={{
                                    top: {
                                        xs: "20%",  // Ajusta según la resolución
                                        sm: "25%",
                                        md: "30%",
                                        lg: "35%",
                                    },
                                    padding: {
                                        xs: "10px",  // Menor padding en pantallas pequeñas
                                        sm: "15px",
                                        md: "20px",
                                        lg: "25px",  // Mayor padding en pantallas grandes
                                    },
                                    left: isNonMobile ? "35%" : "0",
                                    right: isNonMobile ? undefined : "0",
                                    margin: isNonMobile ? undefined : "0 auto",
                                    maxWidth: isNonMobile ? undefined : "240px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: ".5rem",   // Tamaño para pantallas pequeñas
                                            sm: ".7rem",   // Tamaño para pantallas medianas
                                            md: ".9rem",   // Tamaño para pantallas más grandes
                                            lg: "1.1rem"    // Tamaño para pantallas grandes
                                        }
                                    }}
                                    color={shades.secondary[200]}>!Aprovecha nuestra oferta de este mes!</Typography>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: "1rem",   // Tamaño para pantallas pequeñas
                                            sm: "1.5rem",   // Tamaño para pantallas medianas
                                            md: "2rem",   // Tamaño para pantallas más grandes
                                            lg: "2.5rem"    // Tamaño para pantallas grandes
                                        }
                                    }}
                                >10% descuento!</Typography>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: ".5rem",   // Tamaño para pantallas pequeñas
                                            sm: ".7rem",   // Tamaño para pantallas medianas
                                            md: ".9rem",   // Tamaño para pantallas más grandes
                                            lg: "1rem"    // Tamaño para pantallas grandes
                                        }
                                    }}
                                    fontWeight="bold" color={shades.secondary[300]}>
                                    Solo hasta 30 de Noviembre!
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </Box>
    );
};

export default MainCarousel;
