import React, { useState, useEffect } from 'react';
import { Wallet } from '@mercadopago/sdk-react';
import { useSelector } from "react-redux";

const PaymentButton = ({ values }) => {
    const checkoutResume = useSelector((state) => state.cart.checkoutResume);
    const cart = useSelector((state) => state.cart.cart);
    const [preferenceId, setPreferenceId] = useState(null);

    // Función para crear la preferencia de pago en el backend
    const createPreference = async () => {
        try {
            const response = await fetch('https://api.ternurareal.mx/api/payments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    checkoutResume: checkoutResume, // Enviamos todo el resumen del checkout
                    title: "Productos TernuraReal",
                    description: 'Productos TernuraReal', // Detalles del producto
                    values: values,
                    products: cart.map((item) => {
                        return {
                            title: `Vestido Princesa /T: ${item.talla}`,
                            quantity: item.count,
                            currency_id: 'MXN',
                            unit_price: checkoutResume.totalPrice,
                            id: item.idInventory.toString(),
                            description: `Vestido de princesa /T: ${item.talla}`,
                            picture_url: "https://api.ternurareal.mx/uploads/1_388f1abd69.webp",
                            category_id: "vestido",
                        }
                    }),
                }),
            });

            const data = await response.json();
            setPreferenceId(data.preferenceId);
        } catch (error) {
            console.error('Error al crear la preferencia de pago:', error);
        }
    };

    useEffect(() => {
        createPreference(); // Llama a la función para crear la preferencia
    }, []);

    return (
        <div id="wallet_container" width="100%">
            {preferenceId ? (
                <Wallet
                    initialization={{ preferenceId: preferenceId }}
                    customization={{
                        texts: {
                            action: 'Pagar ahora', // Texto personalizado del botón
                            valueProp: 'smart_option',
                        },
                    }}

                />

            ) : (
                <p>Cargando método de pago...</p>
            )}
        </div>
    );
};

export default PaymentButton;
