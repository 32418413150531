import React, { useEffect, useRef, useState } from 'react';
import StatusScreenBrick from './StatusScreenBrick';
import { Modal, Box, Typography, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; // Para generar un Idempotency Key único

const PaymentBrick = () => {
  const paymentBrickContainer = useRef(null);
  const brickInstance = useRef(null);
  const [isBrickInitialized, setIsBrickInitialized] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [message, setMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!window.MercadoPago) {
      const script = document.createElement("script");
      script.src = "https://sdk.mercadopago.com/js/v2";
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => setIsBrickInitialized(true);
      return () => {
        document.body.removeChild(script);
      };
    } else {
      setIsBrickInitialized(true);
    }
  }, []);

  const initializePaymentBrick = async () => {
    if (brickInstance.current) {
      brickInstance.current.unmount();
    }

    const publicKey = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY;
    const mp = new window.MercadoPago(publicKey, { locale: 'es-MX' });

    const bricksBuilder = mp.bricks();
    const settings = {
      initialization: {
        amount: 100,
      },
      customization: {
        visual: {
          style: { theme: "default" },
        },
        paymentMethods: {
          creditCard: "all",
          debitCard: "all",
          ticket: "all",
          maxInstallments: 1,
        },
      },
      callbacks: {
        onReady: () => {
          console.log("Payment Brick está listo");
        },
        onSubmit: ({selectedPaymentMethod,  formData }) => {
          const paymentMethodId = selectedPaymentMethod;
          //const issuerId = formData.issuer_id;

          console.log("Payment Method ID:", paymentMethodId);
          //console.log("Issuer ID:", issuerId);
          // Cambiar el envío a realizar directamente a Mercado Pago Payments API
          return new Promise((resolve, reject) => {
            const idempotencyKey = uuidv4(); // Genera una clave única para idempotencia
            fetch("https://api.ternurareal.mx/api/processPayment", {
              method: "POST",
              headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_MERCADO_PAGO_ACCESS_TOKEN}`, // Coloca el Bearer Token aquí
                "Content-Type": "application/json",
                "X-Idempotency-Key": idempotencyKey
              },
              body: JSON.stringify({
                transaction_amount: 100,
                installments: 1,
                capture: true,
                binary_mode: true,
                payment_method_id: selectedPaymentMethod, // Método de pago capturado,
                token: formData.token,
                external_reference: "PKJNWD1231",
                notification_url: "https://api.ternurareal.mx/api/webhooks/mercadopago",
                metadata: {
                  order_number: "order_PKJNWD1231"
                },
                payer: {
                  first_name: "Julio",
                  last_name: "Rangel",
                  email: "yultrader7@gmail.com",
                },
                statement_descriptor: "TernuraReal Online",
                description: "Compra en TernuraReal",
                additional_info: {
                  items: [
                    {
                      id: "1941",
                      title: "Vestido Elsa",
                      description: "Hermoso vestido inspirado en la pelicula de frozen",
                      picture_url: "https://api.ternurareal.mx/uploads/1_96f17193af.webp",
                      category_id: "retail",
                      quantity: 1,
                      unit_price: 100
                    }
                  ],
                  payer: {
                    first_name: "Julio",
                    last_name: "Rangel",
                    is_prime_user: "-1",
                    is_first_purchase_online: "-1",
                    phone: {
                      area_code: "52",
                      number: "4731196611"
                    },
                    address: {
                      zip_code: "37547",
                      street_name: "Quinta Loreto",
                      street_number: "107"
                    },
                  }
                }
              })
            })
              .then((response) => response.json())
              .then((response) => {
                if (response.id != null) {
                  setPaymentId(response.id);
                  setOpenModal(true);
                }
                if (response.status === "approved") {
                  setMessage("Pago realizado exitosamente.");
                  resolve();
                } else {
                  const userMessage = handleRejection(response.status_detail);
                  setMessage(userMessage);
                  reject();
                }
              })
              .catch((error) => {
                console.error("Error en el pago:", error);
                setMessage("Ocurrió un error al procesar el pago. Intenta nuevamente.");
                reject();
              });
          });
        },
        onError: (error) => {
          console.error("Error en el Brick de Pago:", error);
          setMessage("Ocurrió un error con el formulario de pago. Intenta nuevamente.");
        },
      },
    };

    brickInstance.current = await bricksBuilder.create("cardPayment", "cardPaymentBrick_container", settings);
  };

  const handleRejection = (statusDetail) => {
    switch (statusDetail) {
      case "cc_rejected_insufficient_amount":
        return "Pago rechazado: fondos insuficientes.";
      case "cc_rejected_bad_filled_card_number":
        return "Pago rechazado: número de tarjeta incorrecto.";
      case "cc_rejected_bad_filled_date":
        return "Pago rechazado: fecha de vencimiento incorrecta.";
      case "cc_rejected_bad_filled_other":
        return "Pago rechazado: error en los datos de la tarjeta.";
      case "cc_rejected_call_for_authorize":
        return "Pago rechazado: autoriza el pago con tu banco.";
      case "cc_rejected_high_risk":
        return "Pago rechazado: riesgo alto. Intenta con otro método de pago o comunícate con tu banco.";
      default:
        return "Pago rechazado. Intenta con otra tarjeta o método de pago.";
    }
  };

  useEffect(() => {
    if (isBrickInitialized) initializePaymentBrick();
    return () => {
      if (brickInstance.current) brickInstance.current.unmount();
    };
  }, [isBrickInitialized]);

  return (
    <div>
      <div id="cardPaymentBrick_container" style={{ width: '100%' }} ref={paymentBrickContainer}></div>
      {message && (
        <div style={{ color: message.includes("exitosamente") ? 'green' : 'red', marginTop: '10px' }}>
          {message}
        </div>
      )}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'transparent', p: 0, width: '80%', maxWidth: '600px' }}>
          <Typography variant="h6">Estado de la Transacción</Typography>
          {message && <StatusScreenBrick paymentId={paymentId} />}
          <Button variant="contained" onClick={() => setOpenModal(false)} sx={{ mt: 2 }}>
            Cerrar
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentBrick;
