import { useState, useEffect } from "react";

const useValidateStock = (inventoryId, selectedQuantity, cartQuantity) => {
    const [isValid, setIsValid] = useState(false);
    const [availableStock, setAvailableStock] = useState(null);
    const [stockErrorMessage, setStockErrorMessage] = useState("");

    

    useEffect(() => {
        const checkStock = async () => {
            try {

                if(!inventoryId){
                    setIsValid(false);
                    console.log("Valor incorrecto para inventario.")
                }
                // Llamada a la API para obtener el inventario disponible
                const response = await fetch(`https://api.ternurareal.mx/api/inventarios/${inventoryId}`);
                const dataJson = await response.json();

                const stock = dataJson.data.attributes?.existencia; // Ajusta esto según la estructura de tu API
                setAvailableStock(stock);

                console.log("InventoryId: " + inventoryId)
                console.log("Stock: " + stock + ", CartQuantity:" + cartQuantity + ", SelectedQuantity: " + selectedQuantity);

                // Comparar el stock disponible con la cantidad seleccionada + lo que ya hay en el carrito
                if (selectedQuantity + cartQuantity <= stock) {
                    setIsValid(true);
                    setStockErrorMessage("");
                } else if (selectedQuantity > stock){
                    setIsValid(false);
                    setStockErrorMessage("La cantidad solicitada supera la disponibilidad del producto.");
                } else {
                    setIsValid(false);
                    setStockErrorMessage("Ya cuentas con este producto en tu carrito, no hay más disponibilidad.");
                }
            } catch (error) {
                setIsValid(false);
                setStockErrorMessage("Error al verificar el inventario.");
            }
        };

        checkStock();
    }, [inventoryId, selectedQuantity, cartQuantity]);

    return { isValid, availableStock, stockErrorMessage };
};

export default useValidateStock;
