import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Stepper, Step, StepLabel, useMediaQuery, Typography, Backdrop, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import Payment from "./Payment";
import Shipping from "./Shipping";
import CartDetails from "../account/CartDetails"
import LoginPrompt from "../auth/LoginPrompt"; // Importamos el componente de login
import { theme } from "../../theme";
import styled from "@emotion/styled";

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Checkout = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [processingPayment, setProcessingPayment] = useState(false); // Nuevo estado para controlar el procesamiento de pago
    const cart = useSelector((state) => state.cart.cart);
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated); // Estado de autenticación del usuario
    const isFirstStep = activeStep === 0;
    const isSecondStep = activeStep === 1;
    const isMobile = useMediaQuery("(max-width:600px)");

    // Si el usuario no está autenticado, mostramos el componente de LoginPrompt
    if (!isAuthenticated) {
        return <LoginPrompt from={"checkout"} />;
    }

    const handleFormSubmit = async (values, actions) => {
        setActiveStep(activeStep + 1);

        if (isFirstStep && values.shippingAddress.isSameAddress) {
            actions.setFieldValue("shippingAddress", {
                ...values.billingAddress,
                isSameAddress: true,
            });
        }

        if (isSecondStep) {
            setProcessingPayment(true);  // Mostrar el mensaje de procesamiento
        }

        actions.setTouched({});
    };

    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5",
                width: "100%",
                justifyContent: 'center',
                alignItems: 'flex-start',
                overflow: 'auto',
                display: 'flex',
            }}>

            <Box
                sx={{
                    padding: {
                        xs: isMobile ? "80px 10px 40px 10px" : "130px 10px 40px 10px",  // Menor padding en pantallas pequeñas
                        sm: isMobile ? "80px 15px 40px 15px" : "130px 15px 40px 15px",
                        md: isMobile ? "80px 20px 40px 20px" : "130px 20px 40px 20px",
                        lg: isMobile ? "80px 25px 40px 25px" : "130px 25px 40px 25px",  // Mayor padding en pantallas grandes
                    },
                    m: "0px 5px 0px 5px",
                    width: {
                        xs: isMobile ? "100%":"100%",  // Menor padding en pantallas pequeñas
                        sm: isMobile ? "100%":"95%",
                        md: isMobile ? "100%":"90%",
                        lg: isMobile ? "100%":"85%",  // Mayor padding en pantallas grandes
                    },
                }}>
                <FlexBox
                    alignItems="flex-start"
                    flexDirection={isMobile ? "column" : "row"}
                    sx={{
                        p: "5px 0px 5px 0px",
                        alignItems: "flex-start !important"
                    }}
                >
                    <Box flex="1 1 60%" display="flex" alignItems="flex-start !important" flexDirection="column" justifyContent="space-between" width="100%">
                        <Stepper activeStep={activeStep} sx={{ m: "20px 0", width: '100%' }}>
                            <Step>
                                <StepLabel>Datos de Facturación</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Pago</StepLabel>
                            </Step>
                        </Stepper>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                p: isMobile ? "1px 10px 40px 10px" : "1px 30px 40px 30px",
                                mb: "10px",
                                width: "100%",
                            }}>
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={checkoutSchema[activeStep]}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        {!isFirstStep && (
                                                <Box flex="1 1 50%" display="flex" sx={{justifyContent: isMobile ? "center":"left",}}>
                                                    <Button
                                                        fullWidth
                                                        sx={{
                                                            ...theme.buttons.b1,
                                                            maxHeight: '50px',
                                                            mt: '15px',
                                                            width:{
                                                                xs: isMobile ? "95%":"95%",  // Menor padding en pantallas pequeñas
                                                                sm: isMobile ? "95%":"95%",
                                                                md: isMobile ? "95%":"95%",
                                                                lg: isMobile ? "100%":"160px",  // Mayor padding en pantallas grandes 
                                                            },
                                                            minWidth:"100px",
                                                        }}
                                                        onClick={() => setActiveStep(activeStep - 1)}
                                                    >
                                                        {"<    Atrás"}
                                                    </Button>
                                                </Box>
                                            )}
                                        {isFirstStep && (
                                            <Shipping
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                        {isSecondStep && (
                                            <Payment
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}

                                        <Box display="flex" justifyContent="space-between" gap="10px" flexDirection={isMobile ? 'column' : 'row'}>
                                            
                                            {!isSecondStep && (
                                                <Box flex="1 1 50%" display="flex"
                                                sx={{
                                                    justifyContent:'center',
                                                }}
                                                >
                                                    <Button
                                                        type="submit"
                                                        sx={{
                                                            ...theme.buttons.b1,
                                                            width: isMobile?"100%":"300px",
                                                        }}
                                                    >
                                                        Siguiente
                                                    </Button>
                                                </Box>
                                            )}
                                            {isSecondStep && (
                                                <Box flex="1 1 50%" display="flex">

                                                </Box>
                                            )}

                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                    <Box flex="1 1 40%" display="flex" alignItems="flex-start" sx={{ m: isMobile ? '0px' : '58px 0px 0px 10px', width: '100%' }}>
                        <CartDetails />
                    </Box>
                </FlexBox>
            </Box>

            {/* Mostrar el spinner solo cuando activeStep === 2 y el pago se está procesando */}
            <Backdrop open={processingPayment} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
                <Typography variant="h5" sx={{ ml: 2 }}>Procesando tu pago, por favor espera...</Typography>
            </Backdrop>
        </Box>
    );
};

const initialValues = {
    billingAddress: {
        firstName: "",
        lastName: "",
        country: "México",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
    },
    shippingAddress: {
        isSameAddress: true,
        firstName: "",
        lastName: "",
        country: "México",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
    },
    email: "",
    phoneNumber: "",
};

const checkoutSchema = [
    yup.object().shape({
        billingAddress: yup.object().shape({
            firstName: yup.string().required("Requerido"),
            lastName: yup.string().required("Requerido"),
            country: yup.string().required("Requerido"),
            street1: yup.string().required("Requerido"),
            street2: yup.string(),
            city: yup.string().required("Requerido"),
            state: yup.string().required("Requerido"),
            zipCode: yup.string().required("Requerido"),
        }),
        shippingAddress: yup.object().shape({
            isSameAddress: yup.boolean(),
            firstName: yup.string().when("isSameAddress", {
                is: false,
                then: (schema) => schema.required("Requerido"),
                otherwise: (schema) => schema.notRequired(),
            }),
            lastName: yup.string().when("isSameAddress", {
                is: false,
                then: (schema) => schema.required("Requerido"),
                otherwise: (schema) => schema.notRequired(),
            }),
            country: yup.string().when("isSameAddress", {
                is: false,
                then: (schema) => schema.required("Requerido"),
                otherwise: (schema) => schema.notRequired(),
            }),
            street1: yup.string().when("isSameAddress", {
                is: false,
                then: (schema) => schema.required("Requerido"),
                otherwise: (schema) => schema.notRequired(),
            }),
            street2: yup.string(),
            city: yup.string().when("isSameAddress", {
                is: false,
                then: (schema) => schema.required("Requerido"),
                otherwise: (schema) => schema.notRequired(),
            }),
            state: yup.string().when("isSameAddress", {
                is: false,
                then: (schema) => schema.required("Requerido"),
                otherwise: (schema) => schema.notRequired(),
            }),
            zipCode: yup.string().when("isSameAddress", {
                is: false,
                then: (schema) => schema.required("Requerido"),
                otherwise: (schema) => schema.notRequired(),
            }),
        }),
        email: yup.string().required("Requerido"),
        phoneNumber: yup.string().required("Requerido"),
    }),
    yup.object().shape({
        email: yup.string().required("Requerido"),
        phoneNumber: yup.string().required("Requerido"),
    }),
];

export default Checkout;
