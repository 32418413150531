import { Button } from "@mui/material";
import { theme } from "../../theme";

const LoginButtomPrompt = ({ handleSubmit }) => {
    return (
        <Button
            variant="contained"
            sx={{
                ...theme.buttons.b1,
                m: '20px 0px',
                width: '100%',
            }}
            onClick={handleSubmit} 
        >
            Iniciar sesión
        </Button>
    );
};

export default LoginButtomPrompt;