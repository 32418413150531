// shippingReducer.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shippingCost: 0, // Estado inicial del costo de envío
};

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    setShippingCost: (state, action) => {
      state.shippingCost = action.payload;
    },
  },
});

export const { setShippingCost } = shippingSlice.actions;
export default shippingSlice.reducer;
