import { Box, Typography, useMediaQuery } from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const Rules = ({}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    return (
        <Box 
            width={isNonMobile ? "80%" : "100%"} 
            margin={isNonMobile ? "120px auto" : "80px auto"} 
            padding="20px"
            sx={{ backgroundColor: '#f7f7f7', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}
        >
            {/* Política de Entrega */}
            <Box display="flex" alignItems="center" mb="20px">
                <LocalShippingIcon sx={{ fontSize: 40, color: '#e10098', mr: 2 }} />
                <Typography variant="h4" gutterBottom sx={{ color: '#e10098', fontWeight: 'bold', fontSize: '18px' }}>
                    Política de Entrega
                </Typography>
            </Box>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px'}}>
                <strong>1. Cobertura de Envíos</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - Realizamos envíos a toda la República Mexicana.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>2. Tiempos de Entrega</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - Realizamos envíos lunes y miércoles.<br />
                - Los productos en existencia se entregan de <strong>2 a 3 días hábiles</strong> al interior de la República Mexicana.<br />
                - Los productos con tallas que no estén en existencia se entregarán en un plazo de <strong>8 a 12 días hábiles</strong>.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>3. Seguimiento de Pedidos</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - Una vez procesado tu pedido, recibirás un número de guía en tu correo electrónico para que puedas darle seguimiento en "Sigue tu orden".
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>4. Costos de Envío</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
            - Standard al interior de la República (Envíos lunes y miércoles): <strong>$150.00</strong>.<br />
                - EXPRESS (Envío al día siguiente): <strong>$450.00</strong> aproximadamente.<br />
                - León, Guanajuato (Envío al dia siguiente): <strong>Gratis</strong>.
            </Typography>

            <hr style={{ margin: '20px 0', border: 'none', borderTop: '1px solid #e0e0e0' }} />

            {/* Política de Devoluciones */}
            <Box display="flex" alignItems="center" mb="20px">
                <AssignmentReturnIcon sx={{ fontSize: 40, color: '#e10098', mr: 2 }} />
                <Typography variant="h4" gutterBottom sx={{ color: '#e10098', fontWeight: 'bold', fontSize: '18px' }}>
                    Política de Devoluciones
                </Typography>
            </Box>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>1. Plazo para Devoluciones</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - Aceptamos devoluciones hasta 3 días hábiles después de haber recibido tu producto, siempre que se encuentre en perfectas condiciones (sin manchas, daños o signos de uso).
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>2. Condiciones para la Devolución</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - El producto debe estar sin uso y en las mismas condiciones en que lo recibiste, con el empaque original intacto.<br />
                - El producto haya sido recibido por el cliente con algún daño de fábrica o que no cumpla con la expectativa del cliente.<br />
                - Deberás proporcionar tu recibo o comprobante de compra.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>3. Proceso de Devolución</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - Para iniciar una devolución, comunícate con nosotros a través de WhatsApp o correo electrónico.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>4. Productos No Elegibles para Devolución</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - Productos dañados, rotos o manchados no son elegibles para devolución.
            </Typography>

            <hr style={{ margin: '20px 0', border: 'none', borderTop: '1px solid #e0e0e0' }} />

            {/* Política de Reembolsos */}
            <Box display="flex" alignItems="center" mb="20px">
                <MonetizationOnIcon sx={{ fontSize: 40, color: '#e10098', mr: 2 }} />
                <Typography variant="h4" gutterBottom sx={{ color: '#e10098', fontWeight: 'bold', fontSize: '18px' }}>
                    Política de Reembolsos
                </Typography>
            </Box>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>1. Reembolso del Producto</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - En caso de devolución aprobada, reembolsaremos el costo total del producto menos los gastos de envío (si aplican).
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>2. Tiempo para Procesar el Reembolso</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - Los reembolsos se procesarán en un plazo de 5 a 10 días hábiles después de recibir el producto devuelto.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>3. Método de Reembolso</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - El reembolso se realizará a través del mismo método de pago utilizado en la compra.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px' }}>
                <strong>4. Envíos de Devolución</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#000', fontSize: '12px', pl:'15px'}}>
                - El cliente será responsable de los costos de envío para devolver el producto, excepto si el producto tiene un defecto de fábrica.
            </Typography>
        </Box>
    );
};

export default Rules;
