import { Box, Typography, TextField, Link, Modal, CircularProgress } from "@mui/material";
import { useNavigate, NavLink } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { theme } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup"; // Para las validaciones
import SignupButtomPrompt from "./SignupButtonPrompt";
import { useState } from "react";

const Signup = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [apiError, setApiError] = useState(null); // Estado para los errores de la API
    const [successMessage, setSuccessMessage] = useState(false); // Estado para el éxito
    const [openModal, setOpenModal] = useState(false);

    // Esquema de validación con Yup
    const validationSchema = yup.object().shape({
        email: yup.string().email("Email no válido").required("Email es requerido"),
        password: yup.string().min(6, "La contraseña debe tener al menos 6 caracteres").required("Contraseña es requerida"),
    });

    const handleFormSubmit = async (values) => {
        try {
            // Limpiar errores y mensaje de éxito antes de realizar la solicitud
            setApiError(null);
            setSuccessMessage(false);

            // Realiza la solicitud POST al endpoint de registro de Strapi usando fetch
            const response = await fetch('https://api.ternurareal.mx/api/auth/local/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: values.email, // Usar email como username
                    email: values.email,
                    password: values.password,
                }),
            });

            // Manejar la respuesta
            const data = await response.json();

            if (response.ok) {
                console.log("Usuario registrado:", data);
                //setSuccessMessage(true); // Mostrar mensaje de éxito

                // Mostrar el modal de éxito
                setOpenModal(true);

                // Esperar 3 segundos antes de redirigir
                setTimeout(() => {
                    navigate("/login"); // Cambia '/dashboard' según tu ruta de éxito
                }, 3000);
            } else {
                // Traducir el mensaje de error específico
                if (data.error?.message === "Email or Username are already taken") {
                    setApiError("El correo o nombre de usuario ya están en uso.");
                } else {
                    setApiError("Error al registrar el usuario. Intenta nuevamente.");
                }
            }
        } catch (error) {
            console.error("Error al conectar con el servidor:", error);
            setApiError("Error al conectar con el servidor. Intenta nuevamente.");
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "#fff",
            }}
        >
            <Box sx={{
                ...theme.box.loginPrompt,
                width: isMobile ? "95%" : "400px",
            }}>
                <Typography
                    sx={{
                        ...theme.fontType.title8,
                        textAlign: 'center',
                        color: '#000',
                        marginBottom: '20px'
                    }}
                >
                    Crear cuenta
                </Typography>
                <Typography
                    sx={{
                        ...theme.fontType.title1,
                        textAlign: 'left',
                        color: '#666',
                        marginBottom: '20px',
                        fontWeight: 'normal',
                    }}
                >
                    Es muy simple, crea una cuenta para obtener beneficios como promociones y descuentos.
                </Typography>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                            <Box sx={{ marginBottom: '20px' }}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Correo electrónico"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Box>
                            <Box sx={{ marginBottom: '20px' }}>
                                <TextField
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    // Detectar el evento onKeyDown y ejecutar el submit si la tecla presionada es "Enter"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSubmit();
                                        }
                                    }}
                                />
                            </Box>
                            {/* Mostrar errores de API por encima del botón */}
                            {apiError && (
                                <Typography color="error" sx={{ marginBottom: '20px', textAlign: 'center' }}>
                                    {apiError}
                                </Typography>
                            )}

                            <Box display="flex" justifyContent="center" alignItems="center">
                                <SignupButtomPrompt handleSubmit={handleSubmit} />
                            </Box>
                            <Box display="flex" justifyContent="left" alignItems="flex-start" marginTop="20px">
                                <Typography sx={{ ...theme.fontType.title1 }}>
                                    ¿Ya tienes una cuenta?
                                </Typography>
                                <Link
                                    component={NavLink}
                                    to="/login"
                                    sx={{
                                        ...theme.fontType.title1,
                                        color: "#e10098", // Estilo de enlace en rosa
                                        marginLeft: "5px",
                                        "&:hover": {
                                            cursor: "pointer",
                                            color: "#ff66b2", // Cambiar de color al pasar el ratón
                                        },
                                    }}
                                >
                                    Iniciar sesión
                                </Link>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>

            {/* Modal de éxito */}
            <Modal open={openModal} aria-labelledby="success-modal" aria-describedby="success-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '400px',
                        bgcolor: '#cc007a',
                        borderColor: '#cc007a !important',
                        borderRadius: 2,
                        p: 4,
                        boxShadow: 24,
                        textAlign: 'center',
                        userSelect: 'none',
                        outline: 'none', // Desactiva el borde de enfoque predeterminado
                        '&:focus': {
                            border: '2px solid #cc007a', // Color personalizado para el borde al hacer foco
                            cursor: 'default',
                            outline: 'none', // Asegura que el borde de enfoque no aparezca
                        },
                    }}
                >
                    <Typography id="success-modal" variant="h6" sx={{ color: "#fff", mb: 2 }}>
                        ¡Creación de cuenta exitoso!
                    </Typography>
                    <Typography id="success-description" sx={{ color: "#ccc", mb: 2 }}>
                        Te estamos redirigiendo al inicio de sesión...
                    </Typography>
                    <CircularProgress sx={{ color: "#fff" }} />
                </Box>
            </Modal>
        </Box>
    );
};

export default Signup;
