const validateStock = async (inventoryId, selectedQuantity, cartQuantity) => {
    try {
      if (!inventoryId) {
        console.error("Valor incorrecto para inventario.");
        return { availableStock: 0, isValid: false, stockErrorMessage: "Valor incorrecto para inventario." };
      }
  
      // Llamada a la API para obtener el inventario disponible
      const response = await fetch(`https://api.ternurareal.mx/api/inventarios/${inventoryId}`);
      const dataJson = await response.json();
      const stock = dataJson.data.attributes?.existencia; // Ajusta esto según la estructura de tu API
  
      // Comparar el stock disponible con la cantidad seleccionada + lo que ya hay en el carrito
      if (selectedQuantity <= stock) {
        return { availableStock: stock, isValid: true, stockErrorMessage: "" };
      } else if (selectedQuantity > stock) {
        return { availableStock: stock, isValid: false, stockErrorMessage: "La cantidad solicitada supera la disponibilidad del producto." };
      }
    } catch (error) {
      return { availableStock: 0, isValid: false, stockErrorMessage: "Error al verificar el inventario." };
    }
  };
  
  export default validateStock;