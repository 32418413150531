import { useState } from "react";
import { IconButton, Box, Typography, useTheme, Dialog, DialogContent, Button } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from '@mui/icons-material/Star';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { shades, theme } from "../theme";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import ReactGA from 'react-ga4';

const Item = ({ item, width }) => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [zoomOpen, setZoomOpen] = useState(false);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const isMobile = useMediaQuery("(max-width:600px)");

    const {
        palette: { neutral },
    } = useTheme();

    const { category, price, name, image, shortDescription, marca, discount } = item.attributes;
    const discountedPercent = discount == null ? 0 : discount;
    const discountedPrice = Math.round(price * (1 - discountedPercent / 100)); // Calcula el precio con descuento y redondea

    const imageUrl = image?.data[0]?.attributes?.url || "";
    const marcaProducto = marca?.data?.attributes?.Marca || "No disponible";

    const handleZoomOpen = () => {
        setZoomOpen(true);
        setZoomLevel(1);
    };

    const handleZoomClose = () => {
        setZoomOpen(false);
    };

    const handleZoomIn = () => setZoomLevel((prev) => prev + 0.2);
    const handleZoomOut = () => setZoomLevel((prev) => Math.max(1, prev - 0.2));

    const handleImageLoad = (event) => {
        const { width, height } = event.target;
        setImageSize({ width, height });
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    };

    const handleItemDetails = (itemId, path) => {
        ReactGA.event({
            category: 'Consulta',
            action: 'ConsultarProducto',
            label: itemId + ' -> ' + name
        });
        navigate(path);
    };

    return (
        <Box width={width} sx={{ padding: '5px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: "#fff" }}>
            <Box
                position="relative"
                onMouseOver={() => !isMobile && setIsHovered(true)}
                onMouseOut={() => !isMobile && setIsHovered(false)}
                sx={{ transition: 'transform 0.3s ease-in-out', '&:hover': { transform: isMobile ? 'none' : 'scale(1.05)' } }}
            >
                <img
                    alt={name}
                    width="100%"
                    height="auto"
                    src={`https://api.ternurareal.mx${imageUrl}`}
                    onClick={() => handleItemDetails(item.id, `/item/${item.id}`)}
                    style={{ cursor: 'pointer', borderRadius: '5px' }}
                    onLoad={handleImageLoad}
                />

                <Box
                    position="absolute"
                    top="10px"
                    right="10px"
                    display={isMobile || isHovered ? 'block' : 'none'}
                >
                    <IconButton onClick={handleZoomOpen} sx={{ backgroundColor: 'white' }}>
                        <ZoomInIcon />
                    </IconButton>
                </Box>
            </Box>

            <Box m="10px 10px 0px 10px"
                onClick={() => handleItemDetails(item.id, `/item/${item.id}`)}
                style={{ cursor: 'pointer'}}
            >
                <Typography sx={{ ...theme.fontType.title1, textAlign: 'center' }}>{name}</Typography>

                <Typography sx={{ ...theme.paragraph.p }}>
                    {isMobile
                        ? truncateText(shortDescription[0]?.children[0]?.text || "", 60)
                        : shortDescription[0]?.children[0]?.text || "Descripción no disponible"}
                </Typography>

                <Typography variant="subtitle2" color={neutral.dark} sx={{ mt: "4px" }}>
                    <Typography component="span" fontWeight="bold">Categoría:</Typography>
                    {category.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())} /{" "}
                    <Typography component="span" fontWeight="bold">Marca:</Typography> {marcaProducto}
                </Typography>

                {/* Precio original tachado y precio con descuento */}
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ m: '4px', p: '2px' }}>

                    {discountedPercent > 0 ? (
                        <Box display='flex'>
                            <Typography
                                sx={{
                                    textDecoration: "line-through",
                                    color: "gray",
                                    mr: 1,
                                    flexDirection: 'column'
                                }}
                            >
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(price)}
                            </Typography>
                            <Typography variant="h6" color="red" fontWeight="bold">
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(discountedPrice)}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography variant="h6" color="black" fontWeight="bold">
                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(discountedPrice)}
                        </Typography>
                    )}
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
                    {Array(4).fill().map((_, i) => (
                        <StarIcon key={i} sx={{ color: '#FFD700' }} />
                    ))}
                    <StarIcon sx={{ color: '#FFD700', fill: 'white', stroke: '#FFD700', strokeWidth: 1 }} />
                </Box>
            </Box>

            <Dialog open={zoomOpen} onClose={handleZoomClose} maxWidth="md" fullWidth>
                <DialogContent sx={{ position: 'relative', overflow: 'hidden' }}>
                    <IconButton
                        onClick={handleZoomClose}
                        sx={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'white', zIndex: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box
                        sx={{
                            width: '100%',
                            height: '700px',
                            overflow: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'inline-block',
                                transform: `scale(${zoomLevel})`,
                                transformOrigin: 'center',
                                transition: 'transform 0.3s ease',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                        >
                            <img
                                alt={item.name}
                                src={`https://api.ternurareal.mx${imageUrl}`}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                }}
                                onLoad={handleImageLoad}
                            />
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        position="absolute"
                        bottom="50px"
                        left="40%"
                        zIndex={10}
                        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', padding: '10px' }}
                    >
                        <Button onClick={handleZoomOut} sx={{ mx: 1, color: 'white' }}>
                            <RemoveIcon />
                        </Button>
                        <Button onClick={handleZoomIn} sx={{ mx: 1, color: 'white' }}>
                            <AddIcon />
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Item;
