import { Box, InputBase, Divider, Typography, IconButton } from "@mui/material";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import { useState } from "react";

const Subscribe = () => {
    const [email, setEmail] = useState("");

    return (
        <Box width="80%" margin="80px auto" textAlign="center">
            <IconButton>
                <MarkEmailReadOutlinedIcon fontSize="large"></MarkEmailReadOutlinedIcon>
            </IconButton>
            <Typography variant="h3">Suscríbete a nuestro correo</Typography>
            <Typography>y recibe notificaciones sobre nuevos productos.</Typography>
            <Box
                p="2px 4px"
                m="15px auto"
                display="flex"
                alignItems="center"
                width="75%"
                backgroundColor="#fff"
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Ingresar correo"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                >
                </InputBase>
                <Divider sx={{ height: 28, m: 0.5}} orientation="vertical"></Divider>
                <Typography sx={{ p: "10px", ":hover": {cursor:"pointer"}}}>Suscribirse</Typography>
            </Box>
        </Box>
    );
};

export default Subscribe;