import React from 'react';
import { CardPayment } from '@mercadopago/sdk-react';

const CardPaymentBrick = () => {
  const initialization = {
    amount: 100,
  };

  const onSubmit = async (formData) => {
    // Callback llamado al hacer clic en el botón de enviar datos
    return new Promise((resolve, reject) => {
      fetch('http://localhost:1338/api/processPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((response) => {
          // recibir el resultado del pago
          resolve();
        })
        .catch((error) => {
          // manejar la respuesta de error al intentar crear el pago
          reject();
        });
    });
  };

  const onError = async (error) => {
    // Callback llamado para todos los casos de error de Brick
    console.log(error);
  };

  const onReady = async () => {
    /*
      Callback llamado cuando Brick está listo.
      Aquí puedes ocultar cargamentos de su sitio, por ejemplo.
    */
  };

  return (
    <CardPayment
      initialization={initialization}
      onSubmit={onSubmit}
      onReady={onReady}
      onError={onError}
    />
  );
};

export default CardPaymentBrick;
