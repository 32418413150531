import { Box, Typography, TextField, Link, Modal, CircularProgress } from "@mui/material";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { theme } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup"; // Para las validaciones
import { useState } from "react";
import LoginButtonPrompt from "./LoginButtonPrompt";
import { useDispatch } from 'react-redux';
import { setUser } from '../../state/userReducer'; // Importar la acción setUser
import { useSelector } from "react-redux";

const Login = ({ from }) => {
    const cart = useSelector((state) => state.cart.cart);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [apiError, setApiError] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch(); // Definir dispatch aquí
    const { param } = useParams();

    // Esquema de validación con Yup
    const validationSchema = yup.object().shape({
        email: yup.string().email("Email no válido").required("Email es requerido"),
        password: yup.string().min(6, "La contraseña debe tener al menos 6 caracteres").required("Contraseña es requerida"),
    });

    const handleFormSubmit = async (values) => {
        try {
            // Limpiar el mensaje de error antes de enviar la solicitud
            setApiError(null);

            // Realiza la solicitud POST al endpoint de autenticación de Strapi usando fetch
            const response = await fetch('https://api.ternurareal.mx/api/auth/local', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    identifier: values.email, // En Strapi, el campo se llama "identifier"
                    password: values.password,
                }),
            });

            // Manejar la respuesta
            const data = await response.json();
            if (response.ok) {
                console.log("Inicio de sesión exitoso:", data);

                // Guardar el token de autenticación
                localStorage.setItem("token", data.jwt);
                localStorage.setItem("user", JSON.stringify(data.user));

                // Mostrar el modal de éxito antes de actualizar Redux
                setOpenModal(true);

                // Establecer un pequeño retraso para permitir que el modal se muestre correctamente antes de la actualización de Redux
                setTimeout(() => {
                    // Guardar datos del usuario en Redux
                    dispatch(setUser({ user: data.user }));
                    setOpenModal(false); // Cierra el modal antes de navegar
                    // Redirigir a la URL de origen o a la página principal
                    if (!from) {
                        if (cart.length > 0) {
                            navigate("/checkout");
                        }
                        else {
                            navigate("/");
                        }
                    } else {
                        navigate("/checkout");
                    }
                }, 2000);
            } else {
                console.error("Error de inicio de sesión:", data.message || "Error desconocido");
                // Manejar el mensaje de error en español
                if (data.error?.message === "Invalid identifier or password") {
                    setApiError("Correo o contraseña incorrectos.");
                } else {
                    setApiError("Error al iniciar sesión. Intenta nuevamente.");
                }
            }
        } catch (error) {
            console.error("Error al conectar con el servidor:", error);
            setApiError("Error al conectar con el servidor. Intenta nuevamente.");
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "#fff",
            }}
        >
            <Box sx={{
                ...theme.box.loginPrompt,
                width: isMobile ? "95%" : "400px",
            }}>
                <Typography
                    sx={{
                        ...theme.fontType.title8,
                        textAlign: 'center',
                        color: '#000',
                        marginBottom: '20px'
                    }}
                >
                    Iniciar sesión
                </Typography>
                <Typography
                    sx={{
                        ...theme.fontType.title1,
                        textAlign: 'left',
                        color: '#666',
                        marginBottom: '20px',
                        fontWeight: 'normal',
                    }}
                >
                    Accede a tu cuenta para dar seguimiento a tus compras, obtener promociones y más...
                </Typography>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                            <Box sx={{ marginBottom: '20px' }}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Correo electrónico"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Box>
                            <Box sx={{ marginBottom: '20px' }}>
                                <TextField
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSubmit();
                                        }
                                    }}
                                />
                            </Box>
                            {apiError && (
                                <Typography color="error" sx={{ marginBottom: '20px', textAlign: 'center' }}>
                                    {apiError}
                                </Typography>
                            )}
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <LoginButtonPrompt handleSubmit={handleSubmit} />
                            </Box>
                            <Box display="flex" justifyContent="left" alignItems="flex-start" marginTop="20px">
                                <Typography sx={{ ...theme.fontType.title1 }}>
                                    ¿No tienes cuenta?
                                </Typography>
                                <Link
                                    component={NavLink}
                                    to="/signup"
                                    sx={{
                                        ...theme.fontType.title1,
                                        color: "#e10098", // Estilo de enlace en rosa
                                        marginLeft: "5px",
                                        "&:hover": {
                                            cursor: "pointer",
                                            color: "#ff66b2", // Cambiar de color al pasar el ratón
                                        },
                                    }}
                                >
                                    Crear cuenta
                                </Link>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>

            {/* Modal de éxito */}
            <Modal open={openModal} aria-labelledby="success-modal" aria-describedby="success-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '400px',
                        bgcolor: '#cc007a',
                        borderColor: '#cc007a !important',
                        borderRadius: 2,
                        p: 4,
                        boxShadow: 24,
                        textAlign: 'center',
                        userSelect: 'none',
                        outline: 'none', // Desactiva el borde de enfoque predeterminado
                        '&:focus': {
                            border: '2px solid #cc007a', // Color personalizado para el borde al hacer foco
                            cursor: 'default',
                            outline: 'none', // Asegura que el borde de enfoque no aparezca
                        },
                    }}
                >
                    <Typography id="success-modal" variant="h6" sx={{ color: "#fff", mb: 2 }}>
                        ¡Inicio de sesión exitoso!
                    </Typography>
                    <Typography id="success-description" sx={{ color: "#ccc", mb: 2 }}>
                        Te estamos redirigiendo a tu cuenta...
                    </Typography>
                    <CircularProgress sx={{ color: "#fff" }} />
                </Box>
            </Modal>

        </Box>
    );
};

export default Login;
