import { Box, Checkbox, FormControlLabel, Typography, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressForm from "./AddressForm";
import { setShippingCost } from "../../state/shippingReducer"; // Importar la acción
import Shipment from "./Shipment";
import { theme } from "../../theme";

const Shipping = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const dispatch = useDispatch();
  let shippingCost = useSelector((state) => state.shipping.shippingCost); // Obtener el costo de envío desde Redux

  // Función para obtener el costo de envío según el estado seleccionado
  const updateShippingCost = (selectedState) => {
    fetch("/estadosMexico.json")
      .then((response) => response.json())
      .then((data) => {
        const stateData = data.find((estado) => estado.name === selectedState);
        if (stateData) {
          dispatch(setShippingCost(stateData.shippingCost)); // Despacha la acción para actualizar el costo de envío
        } else {
          dispatch(setShippingCost(0)); // Establecer el costo a 0 si no se encuentra el estado
        }
      })
      .catch((error) => console.error("Error al cargar los datos de estados:", error));
  };

  // useEffect para actualizar el costo de envío basado en la dirección seleccionada
  useEffect(() => {
    // Usar billingAddress si el checkbox está habilitado, de lo contrario usar shippingAddress
    const selectedState = values.shippingAddress.isSameAddress
      ? values.billingAddress.state
      : values.shippingAddress.state;

    if (selectedState) {
      updateShippingCost(selectedState);
    } else {
      dispatch(setShippingCost(0)); // Si no hay un estado seleccionado, establece el costo a 0
      
    }

  }, [values.billingAddress.state, values.shippingAddress.state, values.shippingAddress.isSameAddress, dispatch]);

  return (
    <Box m="20px 10px 10px 10px">
      {/* CONTACT INFO */}
      <Box sx={{mb:'30px'}}>
        <Typography variant="h3" sx={{ mb: "15px", fontWeight:"bold"}}>
          Información de contacto
        </Typography>
        <TextField
          fullWidth
          type="text"
          label="Email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          name="email"
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          sx={{ gridColumn: "span 4", marginBottom: "15px" }}
        />
        <TextField
          fullWidth
          type="text"
          label="Número telefónico"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.phoneNumber}
          name="phoneNumber"
          error={!!touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          sx={{ gridColumn: "span 4" }}
        />
      </Box>
      {/* BILLING FORM */}
      <Box>
        <Typography variant="h3" sx={{ mb: "15px", fontWeight: "bold" }}>
          Información de Facturación
        </Typography>
        <AddressForm
          type="billingAddress"
          values={values.billingAddress}
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </Box>

      <Box mb="20px">
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              checked={values.shippingAddress.isSameAddress}
              value={values.shippingAddress.isSameAddress}
              onChange={() =>
                setFieldValue(
                  "shippingAddress.isSameAddress",
                  !values.shippingAddress.isSameAddress
                )
              }
              sx={{
                ...theme.fontType.title1,
              }}
            />
          }
          label="Usar la dirección de facturación como dirección de envío"
        />
      </Box>

      {/* SHIPPING FORM */}
      {!values.shippingAddress.isSameAddress && (
        <Box>
          <Typography variant="h3" sx={{ mb: "15px", fontWeight: "bold" }}>
            Dirección de envío
          </Typography>
          <AddressForm
            type="shippingAddress"
            values={values.shippingAddress}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </Box>
      )}

      <Box>
        <Typography variant="h3" sx={{ mb: "15px", fontWeight: "bold" }}>
          Costo de envío
        </Typography>
        <Shipment cost={shippingCost} />
      </Box>
    </Box>
  );
};

export default Shipping;
