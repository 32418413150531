import CardPaymentBrick from './CardPaymentBrick';
import PaymentButton from './PaymentButtonMercadoPago';
import { Box, Typography } from "@mui/material";

const Payment = ({ values, touched, errors, handleBlur, handleChange }) => {
  return (
    <Box m="0px">
      <PaymentButton values={values}></PaymentButton> 
      
      {/* <CardPaymentBrick></CardPaymentBrick> */}
    
    </Box>
  );
};

export default Payment;