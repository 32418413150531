import ShoppingList from "../global/ShoppingList";
import MainCarousel from "./MainCarousel";
import Subscribe from "./Subscribe";
import { useLocation } from 'react-router-dom'; // Para obtener el parámetro de categoría seleccionada
import ReactGA from 'react-ga4';

const Home = (cat) => {
    const location = useLocation();  // Obtener la ubicación actual (URL)
    const catParams = new URLSearchParams(location.search);
    const catTerm = catParams.get('cat') || '';  // Obtener el término de categoría de la URL

    let tabs = [];
    let conf = {};  // Inicializa conf como un objeto vacío
    let category = catTerm;  // Nueva variable que se puede modificar   

    // Eventos de Google Analytics
    ReactGA.event({
        category: 'Consulta',
        action: 'IniciarSesion',
        label: ''
      });

    switch (category) {
        case "Vestidos":
            tabs = [
                { label: "Todo", value: "all" },
                { label: "Vestidos", value: "Vestido" },
                { label: "Tutús", value: "Tutu" },
            ];
            break;
        case "Accesorios":
            tabs = [
                { label: "Todo", value: "all" },
                { label: "Coronas", value: "Coronas" },
                { label: "Diademas", value: "Diademas" },
            ];
            break;
        case "Calzado":
            tabs = [
                { label: "Todo", value: "all" },
                { label: "Zapatos", value: "Zapatos" },
            ];
            break;
        case "Search":
            category = "Search";
            tabs = [
                { label: "Resultados", value: "all" },
            ];
            break;
        default:
            category = "Vestidos";
            tabs = [
                { label: "Todo", value: "all" },
                { label: "Vestidos", value: "Vestido" },
                { label: "Tutús", value: "Tutu" },
            ];
            break;
    }

    conf.tabs = tabs;
    conf.currentCategory = category;

    return (
        <div className="home">
            <MainCarousel></MainCarousel>
            <ShoppingList conf={conf}></ShoppingList>
            <Subscribe></Subscribe>
        </div>
    );
};

export default Home;
