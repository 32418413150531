// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Fauna+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  box-sizing: border-box;
}

body{
  margin: 0px;
  padding: 0px;
}

.app {
  margin: auto;
}

#mp-checkout {
  max-width: 100%;
  height: auto;
  overflow: auto;
}

.mercadopago-element iframe {
  height: 40px !important; /* Ajusta a la altura deseada */
  margin: 0; /* Asegura que no haya margen adicional */
  border: solid 1px #ddd;
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,uBAAuB,EAAE,+BAA+B;EACxD,SAAS,EAAE,yCAAyC;EACpD,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Fauna+One&display=swap');\n\n*{\n  box-sizing: border-box;\n}\n\nbody{\n  margin: 0px;\n  padding: 0px;\n}\n\n.app {\n  margin: auto;\n}\n\n#mp-checkout {\n  max-width: 100%;\n  height: auto;\n  overflow: auto;\n}\n\n.mercadopago-element iframe {\n  height: 40px !important; /* Ajusta a la altura deseada */\n  margin: 0; /* Asegura que no haya margen adicional */\n  border: solid 1px #ddd;\n  padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
