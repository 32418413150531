import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { theme } from "../../theme"

const Shipment = ({
    cost,
}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    return (
        <Box>
            {cost === null || cost === 0 ? (
                <label>Selecciona la dirección de envío.</label>
            ) : (
                <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr', // Definimos 2 columnas para la primera fila
                gridTemplateRows: 'auto auto', // 2 filas en total
                gap: '0px', // Espacio entre las celdas
                width: '100%',
            }}
        >
                    {/* Primer fila, columna 1 */}
                    <Box display='flex' alignItems="flex-start" justifyContent="flex-start"
                        sx={{
                            gridColumn: '1 / 2',
                        }}
                    >
                        <Typography
                            sx={{
                                ...theme.fontType.price,
                                color: '#000',
                                fontSize:'1.1em',
                            }}>
                            Envío:
                        </Typography>
                    </Box>

                    {/* Primer fila, columna 2 */}
                    <Box display='flex' alignItems="flex-start" justifyContent="flex-end"
                        sx={{
                            gridColumn: '2 / 3',
                        }}
                    >
                        <Typography
                            sx={{
                                ...theme.fontType.price,
                                color: '#000',
                                fontSize:'1.1em',
                            }}
                        >
                            ${cost}
                        </Typography>
                    </Box>

                    {/* Segunda fila, columna completa */}
                    <Box display='flex'
                        sx={{
                            gridColumn: '1 / 3', // Ocupa ambas columnas
                            backgroundColor:'#f9f9b3',
                            borderRadius:'15px',
                        }}
                    >
                        <Typography 
                        sx={{
                            ...theme.fontType.price,
                            color: '#e90fa3',
                            fontSize:'1.1em',
                            fontWeight: 'bold',
                        }}
                        >Fecha de entrega aproximada:</Typography>
                        <Typography
                        sx={{
                            ...theme.fontType.price,
                            color: '#e90fa3',
                            fontSize:'1.1em',
                        }}
                        >
                        De 2 a 3 días a partir de haber realizado el pago.
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Shipment;
