import { getIn } from "formik";
import { Box, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

const AddressForm = ({
    type,
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const dispatch = useDispatch();
    const [states, setStates] = useState([]);
    const [allStatesData, setAllStatesData] = useState([]); // Para almacenar los datos completos de estados y ciudades
    const [filteredCities, setFilteredCities] = useState([]); // Para almacenar las ciudades filtradas por el estado seleccionado
    const [shippingCost, setShippingCost] = useState(null); // Para almacenar el costo de envío


    // Cargar los estados y ciudades desde el archivo JSON
    useEffect(() => {
        // Ruta al archivo JSON en la carpeta public
        fetch("/estadosMexico.json")
            .then((response) => response.json())
            .then((data) => {
                setStates(data.map((estado) => estado.name));
                setAllStatesData(data); // Guardar la lista completa de estados y ciudades
            })
            .catch((error) => console.error("Error al cargar los datos de estados:", error));
    }, []);

    // Cargar las ciudades cuando cambie el estado seleccionado
    useEffect(() => {
        const selectedState = allStatesData.find(
            (estado) => estado.name === values.state
        );
        if (selectedState) {
            setFilteredCities(selectedState.cities);
            setShippingCost(selectedState.shippingCost); // Actualizar el costo de envío
        } else {
            setFilteredCities([]);
            setShippingCost(null); // Restablecer el costo de envío
        }

    }, [values.state, allStatesData]);

    // Funciones para el manejo del nombre y errores
    const formattedName = (field) => `${type}.${field}`;

    const formattedError = (field) =>
        Boolean(
            getIn(touched, formattedName(field)) &&
            getIn(errors, formattedName(field))
        );

    const formattedHelper = (field) =>
        getIn(touched, formattedName(field)) && getIn(errors, formattedName(field));

    return (
        <Box
            display="grid"
            gap="12px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                mb: '20px'
            }}
        >
            <TextField
                fullWidth
                type="text"
                label="Nombre (s)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name={formattedName("firstName")}
                error={formattedError("firstName")}
                helperText={formattedHelper("firstName")}
                sx={{ gridColumn: "span 2" }}
            />
            <TextField
                fullWidth
                type="text"
                label="Apellido (s)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name={formattedName("lastName")}
                error={formattedError("lastName")}
                helperText={formattedHelper("lastName")}
                sx={{ gridColumn: "span 2" }}
            />
            <TextField
                fullWidth
                type="text"
                label="País"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.country}
                name={formattedName("country")}
                error={formattedError("country")}
                helperText={formattedHelper("country")}
                sx={{ gridColumn: "span 4" }}
                disabled
            />
            <TextField
                fullWidth
                type="text"
                label="Dirección"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.street1}
                name={formattedName("street1")}
                error={formattedError("street1")}
                helperText={formattedHelper("street1")}
                sx={{ gridColumn: "span 2" }}
            />
            <TextField
                fullWidth
                type="text"
                label="Dirección alternativa (opcional)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.street2}
                name={formattedName("street2")}
                error={formattedError("street2")}
                helperText={formattedHelper("street2")}
                sx={{ gridColumn: "span 2" }}
            />
            {/* Select para el Estado */}
            <FormControl fullWidth sx={{ gridColumn: "1fr" }}>
                <InputLabel>Estado</InputLabel>
                <Select
                    fullWidth
                    label="Estado"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={formattedName("state")}
                    error={formattedError("state")}
                    helperText={formattedHelper("state")}
                    sx={{ gridColumn: "span 2" }}
                >
                    {states.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* Select para la Ciudad */}
            <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                <InputLabel>Ciudad</InputLabel>
                <Select
                    fullWidth
                    label="Ciudad"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={formattedName("city")}
                    error={formattedError("city")}
                    helperText={formattedHelper("city")}
                    sx={{ gridColumn: "span 2" }}
                >
                    {filteredCities.map((city) => (
                        <MenuItem key={city} value={city}>
                            {city}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                fullWidth
                type="text"
                label="Código Postal"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zipCode}
                name={formattedName("zipCode")}
                error={formattedError("zipCode")}
                helperText={formattedHelper("zipCode")}
                sx={{ gridColumn: "1fr" }}
            />
        </Box>
        
    );
};

export default AddressForm;
