import React, { useState, useEffect } from "react";
import { Box, Button, Divider, IconButton, Typography, Paper, useMediaQuery, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { shades, theme } from "../../theme";
import {
    decreaseCount,
    increaseCount,
    removeFromCart,
} from "../../state/cartReducer";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import useCartStockInfo from '../../hooks/useCartStockInfo';
import { setPromoDiscountAmount } from './../../state/cartReducer';

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart.cart);
    const stockInfo = useCartStockInfo(cart);
    const isMobile = useMediaQuery("(max-width:600px)");
    const shippingCost = useSelector((state) => state.shipping.shippingCost); // Obtener el costo de envío desde Redux

    const handleIncrement = (item) => {
        const availableStock = stockInfo[item.uniqueId] || 0;
        if (item.count + 1 <= availableStock.availableStock) {
            dispatch(increaseCount({ uniqueId: item.uniqueId, shippingCost }));
        } else {
            console.warn(`No hay más stock disponible para incrementar la cantidad del producto ${item.attributes?.name}.`);
        }
    };

    const handleDecrement = (item) => {
        if (item.count > 1) {
            dispatch(decreaseCount({ uniqueId: item.uniqueId, shippingCost }));
        }
    };

    // Calcula el precio total, considerando el descuento
    const totalPrice = cart.reduce((total, item) => {
        const itemPrice = item.attributes?.price || 0;
        const discount = item.attributes?.discount == null ? 0 : item.attributes?.discount;
        const discountedPrice = Math.round(itemPrice * (1 - discount / 100));
        return total + (item.count ? item.count : 1) * discountedPrice;
    }, 0);

    const handleNavigateToCheckout = () => {
        const promoDiscountAmount = null;
        dispatch(setPromoDiscountAmount({ promoDiscountAmount }));
        ReactGA.event({
            category: 'Compra',
            action: 'CompletarCompra',
            label: ''
        });
        navigate(`/checkout?refresh=${new Date().getTime()}`);
    };

    const IVA = 0.00;
    const totalConIVA = totalPrice + IVA;

    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5",
                minHeight: "100vh",
                padding: isMobile ? "80px 0px 40px 0px" : "120px 0px 40px 0px",
            }}
        >
            <Box
                display="flex"
                justifyContent="left"
                width={isMobile ? "95%" : "80%"}
                margin="0 auto"
                columnGap="20px"
                alignItems="flex-start"
            >
                <Typography
                    sx={{
                        ...theme.fontType.title10,
                        textAlign: 'left',
                        mb: 2,
                        color: 'black'
                    }}
                >
                    Carrito:
                </Typography>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                width={isMobile ? "95%" : "80%"}
                margin="0 auto"
                columnGap="20px"
                alignItems="flex-start"
                flexDirection={isMobile ? "column" : "row"}
            >
                <Box flex="2" width={isMobile ? "100%" : "auto"}>
                    {!isMobile && cart.length > 0 && (
                        <Paper sx={{ padding: 2, mb: 2 }}>
                            <FlexBox sx={{ mb: 0 }}>
                                <Box flex="1 1 40%">
                                    <Typography sx={{ ...theme.fontType.title1 }}>Producto</Typography>
                                </Box>
                                <Box flex="1 1 20%" textAlign="center">
                                    <Typography sx={{ ...theme.fontType.title1 }}>Precio unitario</Typography>
                                </Box>
                                <Box flex="1 1 20%" textAlign="center">
                                    <Typography sx={{ ...theme.fontType.title1 }}>Cantidad</Typography>
                                </Box>
                                <Box flex="1 1 20%" textAlign="center">
                                    <Typography sx={{ ...theme.fontType.title1 }}>Subtotal</Typography>
                                </Box>
                            </FlexBox>
                        </Paper>
                    )}

                    <Paper sx={{ padding: 2 }}>
                        {cart.length > 0 ? (
                            cart.map((item) => {
                                const price = item?.attributes?.price;
                                const discountedPercent = item?.attributes?.discount == null ? 0 : item?.attributes?.discount;
                                const discountedPrice = Math.round(price * (1 - (discountedPercent) / 100)); // Calcula el precio con descuento

                                return (
                                    <Box key={`${item.uniqueId}`} mb={2}>
                                        <FlexBox
                                            p="10px 0"
                                            alignItems="flex-start"
                                            flexDirection={isMobile ? "column" : "row"}
                                        >
                                            <Box flex="1 1 40%" display="flex" alignItems="left" width='100%'>
                                                <Box
                                                    component="a"
                                                    onClick={() => navigate(`/item/${item.id}`)}
                                                    sx={{ alignItems: 'left', alignContent: 'left', cursor: 'pointer' }}
                                                >
                                                    <img
                                                        alt={item.name}
                                                        width="123px"
                                                        height="auto"
                                                        src={`https://api.ternurareal.mx${item?.attributes?.image?.data[0]?.attributes?.formats?.thumbnail?.url || ""}`}
                                                    />
                                                </Box>
                                                <FlexBox flexDirection="column"
                                                    sx={{
                                                        color: '#000',
                                                        textAlign: 'left',
                                                        pt: "5px",
                                                        width: '100%',
                                                        justifyContent: 'flex-start !important',
                                                    }}>
                                                    <Typography
                                                        ml='2'
                                                        sx={{ ...theme.fontType.title1, width: '100%', pl: '10px' }}
                                                    >
                                                        {item.attributes?.name || "Producto desconocido"}
                                                    </Typography>
                                                    <Typography
                                                        ml={2}
                                                        sx={{ ...theme.fontType.miniTitle, textAlign: 'left', width: '100%', pl: '10px', mt: '0px' }}
                                                    >
                                                        {item.talla ? "Talla: " + item.talla : ""}
                                                    </Typography>
                                                    <Tooltip
                                                        title="Quitar del carrito"
                                                        arrow
                                                        placement="top"
                                                        sx={{
                                                            "& .MuiTooltip-tooltip": {
                                                                backgroundColor: "black",
                                                                color: "white",
                                                                fontSize: "0.875rem",
                                                            },
                                                            "& .MuiTooltip-arrow": {
                                                                color: "black",
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            onClick={() => dispatch(removeFromCart({ uniqueId: item.uniqueId, shippingCost }))}
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: "#f0f0f0",
                                                                color: "black",
                                                                '&:hover': {
                                                                    backgroundColor: "#d6d6d6",
                                                                },
                                                                mt: isMobile ? 2 : 0,
                                                                ml: 2,
                                                                alignSelf: isMobile ? "flex-start" : "center",
                                                            }}
                                                        >
                                                            Eliminar
                                                        </Button>
                                                    </Tooltip>
                                                </FlexBox>
                                            </Box>

                                            <FlexBox
                                                flex="1 1 60%"
                                                flexDirection={isMobile ? "row" : "row"}
                                                justifyContent="flex-start"
                                                mt={isMobile ? 2 : 0}
                                                mb={isMobile ? 2 : 0}
                                                sx={{
                                                    textAlign: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                {/* Precio original tachado y precio con descuento */}
                                                {discountedPercent > 0 ? (
                                                    <Box flex="1 1 33%" textAlign="center">
                                                        <Typography
                                                            sx={{
                                                                textDecoration: "line-through",
                                                                color: "gray",
                                                                mr: 1,
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item?.attributes?.price)}
                                                        </Typography>
                                                        <Typography variant="h6" color="red" fontWeight="bold">
                                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(discountedPrice)}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box flex="1 1 33%" textAlign="center">
                                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black', ml: 1 }}>
                                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item?.attributes?.price)}
                                                        </Typography></Box>
                                                )}


                                                <Box
                                                    flex="1 1 34%"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    textAlign="center"
                                                    sx={{ flexDirection: 'column' }}
                                                >
                                                    <Box display='flex' flexDirection='row'>
                                                        <IconButton
                                                            sx={{
                                                                border: "solid 1px #bbb",
                                                                mr: "10px", color: "#000",
                                                                '&:hover': {
                                                                    backgroundColor: "#e10098",
                                                                    color: "#fff"
                                                                },
                                                                borderRadius: "0px"
                                                            }}
                                                            onClick={() => dispatch(decreaseCount({ uniqueId: item.uniqueId }))}
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        <Typography
                                                            sx={{ mt: '10px' }}
                                                        >{item.count ? item.count : 1}</Typography>
                                                        <IconButton
                                                            sx={{
                                                                border: "solid 1px #bbb",
                                                                ml: "10px", color: "#000",
                                                                '&:hover': {
                                                                    backgroundColor: "#e10098",
                                                                    color: "#fff"
                                                                },
                                                                borderRadius: "0px"
                                                            }}
                                                            onClick={() => handleIncrement(item)}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            ml={2}
                                                            sx={{ ...theme.fontType.miniTitle, textAlign: 'left', color: 'green', width: '100%', pl: '10px', mt: '10px' }}
                                                        >
                                                            {stockInfo[item.uniqueId]?.availableStock ? "Disponible: " + stockInfo[item.uniqueId]?.availableStock : ""}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box flex="1 1 33%" textAlign="center">
                                                    <Typography sx={{ ...theme.fontType.price }}>
                                                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format((item.count ? item.count : 1) * discountedPrice)}
                                                    </Typography>
                                                </Box>
                                            </FlexBox>
                                        </FlexBox>
                                        <Divider />
                                    </Box>
                                );
                            })
                        ) : (
                            <Box flex="1 1 100%" justifyContent="center" flexDirection="column" alignItems="flex-start" textAlign="center">
                                <Typography sx={{ ...theme.fontType.title3 }} textAlign="center" mt={4}>
                                    No hay productos en tu carrito.
                                </Typography>
                                <Button
                                    sx={{
                                        ...theme.buttons.b1,
                                        m: '5px 5px',
                                        minWidth: isMobile ? "100%" : "220px",
                                    }}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    Ir a la tienda
                                </Button>
                            </Box>
                        )}
                    </Paper>
                </Box>
                {cart.length > 0 && (
                    <Box flex="1" mt={isMobile ? 4 : 0} width={isMobile ? "100%" : "auto"}>
                        <Paper sx={{ padding: 3, backgroundColor: "#ffffff", boxShadow: 3 }}>
                            <Typography
                                sx={{
                                    ...theme.fontType.title3,
                                    textAlign: 'left',
                                    letterSpacing: '0.5px',
                                    mb: 3,
                                    color: 'black'
                                }}
                            >
                                Resumen del pedido
                            </Typography>

                            {cart.length > 0 && (
                                <Box>
                                    <FlexBox mb="5px">
                                        <Typography sx={{ ...theme.fontType.title1, fontWeight: 'normal', mb: '0px', mt: '0px' }}>Subtotal:</Typography>
                                        <Typography sx={{ ...theme.fontType.title1, fontWeight: 'normal', mb: '0px', mt: '0px' }}>
                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}
                                        </Typography>
                                    </FlexBox>

                                    <FlexBox mb="5px">
                                        <Typography sx={{ ...theme.fontType.title1, fontWeight: 'normal', mb: '0px' }}>Iva incluido:</Typography>
                                        <Typography sx={{ ...theme.fontType.title1, fontWeight: 'normal', mb: '0px' }}>
                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(IVA)}
                                        </Typography>
                                    </FlexBox>
                                    <FlexBox mb="5px">
                                        <Typography sx={{ ...theme.fontType.title1, fontWeight: 'normal', mb: '0px' }}>Gastos de envío:</Typography>
                                        <Typography sx={{ ...theme.fontType.title1, fontWeight: 'normal', mb: '0px' }}>
                                            No incluido
                                        </Typography>
                                    </FlexBox>
                                    <Divider />
                                    <FlexBox m="15px 0px 20px 0px">
                                        <Typography sx={{ ...theme.fontType.title2, color: 'black' }}>Total (IVA incluido):</Typography>
                                        <Typography sx={{ ...theme.fontType.price1, color: 'black' }}>
                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalConIVA)}
                                        </Typography>
                                    </FlexBox>

                                    <Button
                                        sx={{
                                            ...theme.buttons.b1,
                                            m: '10px 0px',
                                            width: '100%',
                                        }}
                                        onClick={() => { handleNavigateToCheckout(); }}
                                    >
                                        Continuar la compra
                                    </Button>
                                    <Button
                                        sx={{
                                            ...theme.buttons.b2,
                                            m: '0px 0px',
                                            width: '100%',
                                        }}
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                    >
                                        Ir a la tienda
                                    </Button>
                                </Box>
                            )}
                        </Paper>
                    </Box>
                )}

            </Box>
        </Box>
    );
};

export default CartMenu;
