import { useTheme } from "@mui/material";
import { Box, Typography, Link } from "@mui/material";
import { shades } from "../../theme";
import logo from '../../assets/TRlogo01_01.png';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Icono de WhatsApp de Material UI
import InstagramIcon from '@mui/icons-material/Instagram'; // Icono de Instagram de Material UI
import FacebookIcon from '@mui/icons-material/Facebook'; // Icono de Facebook de Material UI
import tiktokIcon from '../../assets/tiktok-icon.png';

const Footer = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const {
        palette: { neutral },
    } = useTheme();
    return (
        <>
            <Box mt="10px" p="30px 0" backgroundColor="#333" color="#ddd">
                <Box 
                    width="80%"
                    margin="auto"
                    display="flex"
                    justifyContent="space-between"
                    columnGap="clamp(20px, 30px, 40px)"
                    flexDirection={isMobile ? "column" : "flex"}
                >


                    <Box width="clamp(15%, 100%, 100%)" sx={{ p: "10px" }}>
                        <Box display="flex">
                            <img
                                src={logo}
                                alt="TernuraReal Logo"
                                style={{ width: "40px", height: "40px", marginRight: "10px" }}
                            />
                            <Box
                                sx={{
                                    fontSize: isMobile ? "11px" : "18px",
                                    fontWeight: 'bold',
                                    letterSpacing: '2px',
                                    color: '#fff',
                                    pt: "8px",
                                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                                    transition: 'color 0.3s ease, transform 0.3s ease',
                                    '&:hover': {
                                        color: '#b7007b',
                                        transform: 'scale(1.05)',
                                        cursor: "pointer"
                                    },
                                }}
                            >
                                TernuraReal
                            </Box>
                        </Box>
                        <Typography variant="h4" fontWeight="bold" m="10px 0px 10px 0px">Acerca de nosotros</Typography>
                        <Typography mb="0px" sx={{fontSize:"12px", justifyContent: 'center',textAlign: 'justify' }}>En TernuraReal, compartimos momentos mágicos e inolvidables para los más pequeños con exclusivos vestidos de princesa. Diseñados para que cada niña viva sus sueños en fiestas y eventos, nuestros vestidos combinan encanto, elegancia y calidad. Nos enfocamos en reflejar la personalidad única de cada pequeña, asegurando que se sientan como verdaderas princesas en su día especial.
                        </Typography>
                        <Typography sx={{fontSize:"12px", justifyContent: 'center',textAlign: 'justify' }}>Descubre la magia con TernuraReal y convierte los sueños de tus niñas en realidad.</Typography>
                    </Box>
                    <Box width="clamp(15%, 100%, 100%)" sx={{ p: "10px" }}>
                        <Typography variant="h4" fontWeight="bold" mb="10px">Atención al cliente</Typography>
                        <Typography mb="10px" sx={{fontSize:"12px", justifyContent: 'center',textAlign: 'justify' }}>
                        <Link
                            underline="none"
                            color="inherit"
                            onClick={() => navigate("/order")}
                            sx={{
                                color: "#fff",
                                fontSize: '14px',
                                fontWeight: 'normal',
                                fontFamily: 'Arial, sans-serif',
                                letterSpacing: '0.5px',
                                '&:hover': {
                                    color: "#e10098",
                                    cursor: 'pointer'
                                },
                                fontSize:"12px", justifyContent: 'center',textAlign: 'justify'
                            }}
                        >
                            Sigue tu orden
                        </Link>
                        </Typography>
                        <Link
                            underline="none"
                            color="inherit"
                            onClick={() => navigate("/rules")}
                            sx={{
                                color: "#fff",
                                fontSize: '14px',
                                fontWeight: 'normal',
                                fontFamily: 'Arial, sans-serif',
                                letterSpacing: '0.5px',
                                '&:hover': {
                                    color: "#e10098",
                                    cursor: 'pointer'
                                },
                                fontSize:"12px", justifyContent: 'center',textAlign: 'justify'
                            }}
                        >
                            Entregas, devoluciones y reembolsos
                        </Link>
                    </Box>
                    <Box width="clamp(15%, 100%, 100%)" sx={{ p: "10px 10px 0px 10px" }}>
                        <Typography variant="h4" fontWeight="bold" mb="10px">Contáctanos</Typography>
                        <Typography mb="10px">León, Gto.</Typography>
                        <Typography mb="10px">Email: contacto@ternurareal.mx</Typography>
                        <Typography mb="10px">Términos y Condiciones</Typography>
                        <Typography mb="0px">Whatsapp: (52) 4791416185</Typography>
                    </Box>
                </Box>

                {/* Redes sociales */}
                <Box display="flex" justifyContent="center" mt="20px" gap="20px" sx={{ p: isMobile ? '10px 10px 40px 10px' : '10px 10px 0px 10px' }}>
                    <a
                        href="https://www.instagram.com/ternurarealmx/profilecard/?igsh=YndqOGk0bmtjZWhp"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            backgroundColor: '#E1306C',
                            color: 'white',
                            padding: '5px 5px 0px 5px',
                            borderRadius: '15%',
                            textDecoration: 'none',
                            display: 'inline-block',
                        }}
                    >
                        <InstagramIcon style={{ fontSize: '40px' }} />
                    </a>
                    <a
                        href="https://www.tiktok.com/@vestidos.ternurarealmx?_t=8qNpGOYivrB&_r=1"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            backgroundColor: '#000',
                            color: 'white',
                            padding: '5px 5px 0px 5px',
                            borderRadius: '15%',
                            textDecoration: 'none',
                            display: 'inline-block',
                        }}
                    >
                        <img src={tiktokIcon} alt="TikTok" style={{ width: '40px', height: '40px' }} />
                    </a>
                    <a
                        href="https://www.facebook.com/share/TK6pbMNJUMurCBck/?mibextid=LQQJ4d"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            backgroundColor: '#000',
                            color: 'white',
                            padding: '5px 5px 0px 5px',
                            borderRadius: '15%',
                            textDecoration: 'none',
                            display: 'inline-block',
                        }}
                    >
                        <FacebookIcon style={{ fontSize: '40px', color: '#4280dd' }} />
                    </a>
                </Box>
            </Box>

            {/* Icono flotante de WhatsApp en la parte inferior derecha */}
            <a
                href="https://wa.me/524791416185"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    position: 'fixed',
                    bottom: isMobile ? '60px' : '20px',  // 60px para móviles, 20px para otros dispositivos
                    right: '20px',
                    backgroundColor: '#25D366',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '50%',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    zIndex: '1000',
                    textAlign: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <WhatsAppIcon style={{ fontSize: '40px' }} />
            </a>
        </>
    );
};

export default Footer;
