import Model3DViewer from '../global/Model3DViewer';

import React from 'react';

const Test = () => {
  return (
    <Model3DViewer></Model3DViewer>
  );
};

export default Test; 
 
 