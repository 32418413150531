import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import WhatsappNumber from "../global/WhatsappNumber";
import {
    Card,
    CardContent,
    Typography,
    Box,
    CircularProgress,
    Grid,
    Divider,
    Button,
    useMediaQuery,
} from '@mui/material';
import { theme } from "../../theme";
import { clearCart } from '../../state/cartReducer';

const Success = () => {
    const location = useLocation();
    const [orderDetails, setOrderDetails] = useState(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:600px)");
    const dispatch = useDispatch(); // Hook para interactuar con Redux

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const externalReference = params.get('external_reference');

        if (!externalReference) {
            navigate('/orders'); // Redirigir si falta external_reference
        } else {
            (async () => {
                try {
                    const response = await fetch('https://api.ternurareal.mx/api/orders/extRef', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ external_reference: externalReference }),
                    });

                    if (!response.ok) {
                        throw new Error('Error al obtener los detalles del pedido');
                    }

                    const data = await response.json();
                    if (data?.status == "approved") {
                        dispatch(clearCart()); // Llama la acción para limpiar el carrito
                    }

                    setOrderDetails(data);
                } catch (error) {
                    console.error(error.message);
                }
            })();
        }
    }, [location]);

    if (!orderDetails)
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: "#f5f5f5",
                    minHeight: "100vh",
                    padding: isMobile ? "80px 0px 40px 0px" : "120px 0px 40px 0px",
                    m: "0px 10px 0px 10px",
                }}
            >
                <Card sx={{ maxWidth: 600, width: '100%', borderRadius: 3, boxShadow: 3 }}>
                    <CardContent>
                        <Typography
                            textAlign="center"
                            sx={{ ...theme.fontType.title5 }}
                        >
                            Estatus del pedido:
                        </Typography>
                        <Divider sx={{ mb: 3 }} />

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography textAlign="center" variant="h6" sx={{ fontWeight: 'bold' }}>
                                    No se encontró detalle del pedido:
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider sx={{ my: 3 }} />

                        <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/')}
                                sx={{ ...theme.buttons.b1 }}
                            >
                                Volver al inicio
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        );

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundColor: "#f5f5f5",
                minHeight: "100vh",
                padding: isMobile ? "80px 0px 40px 0px" : "120px 0px 40px 0px",
                m: "0px 10px 0px 10px",
            }}
        >
            <Card sx={{ maxWidth: 600, width: '100%', borderRadius: 3, boxShadow: 3 }}>
                <CardContent>
                    <Typography
                        textAlign="center"
                        sx={{ ...theme.fontType.title5, color: '#03b100' }}
                    >
                        ¡Gracias por tu compra!
                    </Typography>
                    <Divider sx={{ mb: 3 }} />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Detalles del pedido:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                Nombre:
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                                {orderDetails.userName}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                Email:
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                                {orderDetails.email}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                Estado:
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                                {orderDetails.shippingAddress.state}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                Total:
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                                ${orderDetails.checkoutResume.totalPrice.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                Status:
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                                {orderDetails.status}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                Descripción del status:
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                                {orderDetails.statusDescription}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ ...theme.paragraph.p1 }}>
                                En los proximos minutos recibirás un correo electrónico con el detalle y seguimiento de tu compra.
                            </Typography>
                            <Typography variant="h6" sx={{ ...theme.paragraph.p1 }}>
                                Cualquier duda con tu orden puedes contactarnos de 8:00 am a 7:00 pm de lunes a domingo por whatsapp al número <WhatsappNumber /> o al correo electrónico contacto@ternurareal.mx.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider sx={{ my: 3 }} />

                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/')}
                            sx={{ ...theme.buttons.b1 }}
                        >
                            Volver al inicio
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Success;
