import React from "react";

const WhatsappNumber = () => {
    return (
        <a
            href="https://wa.me/524791416185"
            target="_blank"
            rel="noopener noreferrer"
            style={{
                color: '#000',
                padding: '5px',
                zIndex: '1000',
                textAlign: 'center',
                cursor: 'pointer',
                //display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        ><strong>(52) 4791416185</strong>
        </a>
    );
};

export default WhatsappNumber;