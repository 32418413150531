import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from '@mui/material';
import { theme } from "./theme";
import { Provider } from "react-redux";
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { combineReducers } from '@reduxjs/toolkit';
import { createMigrate } from 'redux-persist';
import cartReducer from "./state/cartReducer";
import userReducer from './state/userReducer';
import shippingReducer from "./state/shippingReducer";

// Definimos la versión actual de persistencia
const persistVersion = 1.03;

const migrations = {
  1.03: (state) => {
    return {
      ...state,
      checkoutResume: {
        totalItems: state?.checkoutResume?.totalItems || 0,
        totalPrice: state?.checkoutResume?.totalPrice || 0,
        totalPriceForPromo: state?.checkoutResume?.totalPriceForPromo || 0,
        averagePrice: state?.checkoutResume?.averagePrice || 0,
        promoDiscountAmount: state?.checkoutResume?.promoDiscountAmount || 0,
        promoCode: state?.checkoutResume?.promoCode || null,
        shippingCost: state?.checkoutResume?.shippingCost || 0,
      },
      cart: state.cart || [],
      items: state.items || [],
      isCartOpen: state.isCartOpen || false,
    };
  },
};

// Configuración de redux-persist
const persistConfig = {
  key: 'root',
  storage,
  version: persistVersion,
  migrate: createMigrate(migrations, { debug: true }),
};

// Combina los reducers
const rootReducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
  shipping: shippingReducer,
});

// Crear un persistReducer combinando el reducer con persistConfig
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
);
