import PaymentBrick from '../scenes/checkout/PaymentBrick';
import {Box} from '@mui/material'

const CheckoutForm = () => {
    return(

        <Box sx={{mt:'90px', p:'40px'}}>
        <PaymentBrick></PaymentBrick>
        </Box>
    );
}

export default CheckoutForm;

/**
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, MenuItem, Typography, Container, Paper, CircularProgress } from "@mui/material";
import {
    initMercadoPago,
    CardNumber,
    ExpirationDate,
    SecurityCode,
    createCardToken,
    getIdentificationTypes,
    getInstallments,
} from '@mercadopago/sdk-react';

const CheckoutForm = () => {
    const [cardToken, setCardToken] = useState(null);
    const [payerEmail, setPayerEmail] = useState('');
    const [installments, setInstallments] = useState([]);
    const [selectedInstallments, setSelectedInstallments] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initMercadoPago('TEST-15edfd11-8a69-4ee3-afb0-d0ae94cd48a5'); // Reemplaza con tu clave pública

        getIdentificationTypes()
            .then((types) => console.log('Tipos de identificación:', types))
            .catch((error) => console.error('Error al obtener tipos de identificación:', error));
    }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const token = await createCardToken();
            setCardToken(token);

            const response = await fetch('/api/process_payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token.id,
                    transaction_amount: 100.5,
                    installments: selectedInstallments,
                    description: "Descripción del producto",
                    payer: {
                        email: payerEmail,
                    },
                }),
            });
            const result = await response.json();
            console.log("Pago procesado:", result);
        } catch (error) {
            console.error("Error al crear el token de la tarjeta:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleInstallments = async (amount) => {
        const installmentOptions = await getInstallments({ amount });
        setInstallments(installmentOptions);
    };

    return (
        <Container maxWidth="sm" sx={{ marginTop: 15 }}>
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Paga de forma segura
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                    Completa los datos de tu tarjeta
                </Typography>
                <form id="form-checkout" onSubmit={handleFormSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ border: '1px solid #c4c4c4', borderRadius: 1, padding: '10px 12px' }} class="mercadopago-element">
                            <CardNumber placeholder="Número de tarjeta" sx={{ width: '100%' }} />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box class="mercadopago-element" sx={{ border: '1px solid #c4c4c4', borderRadius: 1, padding: '10px 12px', flex: 1 }}>
                                <ExpirationDate placeholder="MM/AA" sx={{ width: '100%' }} />
                            </Box>
                            <Box class="mercadopago-element" sx={{ border: '1px solid #c4c4c4', borderRadius: 1, padding: '10px 12px', flex: 1 }}>
                                <SecurityCode placeholder="CVC" sx={{ width: '100%' }} />
                            </Box>
                        </Box>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Correo electrónico"
                            type="email"
                            value={payerEmail}
                            onChange={(e) => setPayerEmail(e.target.value)}
                            required
                        />
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Cuotas"
                            value={selectedInstallments}
                            onChange={(e) => setSelectedInstallments(e.target.value)}
                        >
                            {installments.map((installment) => (
                                <MenuItem
                                    key={installment.installments}
                                    value={installment.installments}
                                >
                                    {installment.recommended_message}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={loading}
                            sx={{ textTransform: 'none' }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Pagar'}
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
};

export default CheckoutForm;
**/