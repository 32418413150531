import { useDispatch, useSelector } from 'react-redux';
import { Badge, Box, IconButton, InputBase, Divider, Link, Drawer, List, ListItem, ListItemText, Typography } from "@mui/material";
import {
    MenuOutlined,
    SearchOutlined,
    ShoppingBagOutlined,
    HelpOutlineOutlined,
    ShoppingCartOutlined,
    AccountCircleOutlined,
    LogoutOutlined
} from '@mui/icons-material';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { useNavigate } from 'react-router-dom';
import { shades } from "../../theme";
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { useMediaQuery, ListItemIcon } from '@mui/material';
import logo from '../../assets/TRlogo01_01.png';
import { setCurrentCategory } from '../../state/cartReducer'; // Importar la acción de Redux
import { logoutUser } from '../../state/userReducer'; // Importar la acción de cierre de sesión

const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();  // Añadir dispatch para usar Redux
    const cart = useSelector((state) => state.cart.cart);
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated); // Estado de autenticación
    const isMobile = useMediaQuery('(max-width:600px)');
    const [mainDrawerOpen, setMainDrawerOpen] = useState(false);
    const [userDrawerOpen, setUserDrawerOpen] = useState(false); // Estado para el Drawer de usuario
    const currentCategory = useSelector((state) => state.cart.currentCategory); // Obtener la categoría seleccionada desde Redux
    const [searchTerm, setSearchTerm] = useState("");

    const toggleMainDrawer = (open) => () => {
        setMainDrawerOpen(open);
    };

    const toggleUserDrawer = (open) => () => {
        setUserDrawerOpen(open);
    };

    const handleLogout = () => {
        // Eliminar datos de sesión del localStorage
        localStorage.removeItem("token");
        localStorage.removeItem("user");

        // Despachar la acción de cierre de sesión en Redux
        dispatch(logoutUser());
        setUserDrawerOpen(false);
        navigate("/");
    };

    const handleNavigation = (option, path) => {
        // Eventos de Google Analytics
        ReactGA.event({
            category: 'Consulta',
            action: 'ConsultarCategoria',
            label: option
        });
        dispatch(setCurrentCategory(option));  // Actualizar categoría en Redux
        navigate(path);
    };

    const handleSearch = (term = searchTerm) => {
        // Eventos de Google Analytics
        ReactGA.event({
            category: 'Busqueda',
            action: 'BuscarProducto',
            label: term
        });

        navigate(`/?cat=Search&search=${term}`);
    };

    const handleInputChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);

        if (newSearchTerm === "") {
            handleSearch("");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleNavigateToCart = () => {
        // Eventos de Google Analytics
        ReactGA.event({
            category: 'Compra',
            action: 'IrAlCarrito',
            label: ''
        });
        navigate("/cart");
    };

    const mobileMenuItems = [
        { label: "Mi carrito", onClick: () => handleNavigateToCart(), icon: <ShoppingCartOutlined /> }
    ];

    const menuItems = [
        {
            label: "Vestidos",
            value: "Vestidos",
            onClick: () => handleNavigation("Vestidos", "/?cat=Vestidos")
        }/*,
        {
            label: "Accesorios",
            value: "Accesorios",
            onClick: () => handleNavigation("Accesorios", "/?cat=Accesorios")
        },
        {
            label: "Calzado",
            value: "Calzado",
            onClick: () => handleNavigation("Calzado", "/?cat=Calzado")
        },
        {
            label: "Preguntas frecuentes",
            value: "Faqs",
            onClick: () => navigate("/faq")
        }*/
    ];

    return (
        <>
            {/* Navbar rosa principal */}
            <Box
                display="flex"
                alignItems="center"
                width="100%"
                height="60px"
                backgroundColor="#c70087"
                color="black"
                position="fixed"
                top="0"
                left="0"
                zIndex="1"
            >
                <Box
                    width={isMobile ? "100%" : "80%"}
                    margin="auto"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={isMobile ? "0 10px" : "0"}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        onClick={() => navigate("/")}
                        sx={{ '&:hover': { cursor: "pointer" } }}
                        color={shades.neutral[400]}
                    >
                        <img
                            src={logo}
                            alt="TernuraReal Logo"
                            style={{ width: "40px", height: "40px", marginRight: "10px" }}
                        />
                        <Box sx={{ fontSize: isMobile ? "11px" : "18px", fontWeight: 'bold', color: '#fff' }}>
                            TernuraReal
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            width: isMobile ? "60%" : "50%",
                            padding: "5px",
                            height: "40px"
                        }}
                    >
                        <InputBase
                            value={searchTerm}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Buscar por producto, marca y más..."
                            sx={{ marginLeft: 1, flex: 1, fontSize: isMobile ? "9px" : "12px", color: "#000" }}
                        />
                        <Divider orientation="vertical" sx={{ height: 28, mx: 0.5 }} />
                        <IconButton onClick={() => handleSearch()}
                            sx={{
                                color: "#999",
                                '&:hover': {
                                    color: "#e10098",
                                    cursor: 'pointer',
                                    transform: 'scale(1.1)',
                                    transition: 'all 0.3s ease',
                                }
                            }}
                        >
                            <SearchOutlined />
                        </IconButton>
                    </Box>

                    {isMobile ? (
                        <>
                            <IconButton onClick={toggleMainDrawer(true)} sx={{ color: "#fff" }}>
                                <MenuOutlined />
                            </IconButton>
                            <Drawer
                                open={mainDrawerOpen}
                                onClose={toggleMainDrawer(false)}
                                sx={{
                                    "& .MuiDrawer-paper": {
                                        minWidth: "40vw", // Ajusta el ancho del Drawer
                                    }
                                }}
                            >
                                <List>
                                    {mobileMenuItems.map((item, index) => (
                                        <ListItem
                                            key={index}
                                            onClick={() => { item.onClick(); setMainDrawerOpen(false); }}
                                        >
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.label}
                                                primaryTypographyProps={{
                                                    fontSize: "18px",
                                                    fontWeight: 'bold'
                                                }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Drawer>
                        </>
                    ) : (
                        <Box display="flex" columnGap="20px">
                            <Badge badgeContent={cart?.length} color='secondary'>
                                <IconButton onClick={handleNavigateToCart} sx={{ color: "#fff" }}>
                                    <ShoppingCartOutlined />
                                </IconButton>
                            </Badge>
                            {isAuthenticated && (
                                <IconButton onClick={toggleUserDrawer(true)} sx={{ color: "#fff" }}>
                                    <AccountCircleOutlined />
                                </IconButton>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>

            {/* Drawer para opciones del usuario */}
            <Drawer
                anchor="right"
                open={userDrawerOpen}
                onClose={toggleUserDrawer(false)}
                sx={{ "& .MuiDrawer-paper": { width: "250px" } }}
            >
                <List>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Cerrar sesión" />
                    </ListItem>
                </List>
            </Drawer>

            {/* Navbar blanco en pantallas grandes (debajo del navbar rosa) */}
            {!isMobile && (
                <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    height="45px"
                    backgroundColor="#fff"
                    color="black"
                    position="fixed"
                    top="60px"
                    left="0"
                    zIndex="1"
                    borderBottom="1px solid #ccc"
                >
                    <Box
                        width="80%"
                        margin="auto"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        columnGap="30px"
                    >
                        {menuItems.map((item, index) => (
                            <Link
                                key={index}
                                underline="none"
                                color="inherit"
                                onClick={item.onClick}
                                sx={{
                                    color: currentCategory === item.value ? "#e10098" : '#2d2d2d',
                                    fontSize: '14px',
                                    fontWeight: currentCategory === item.value ? 'bold' : 'normal',
                                    fontFamily: 'Arial, sans-serif',
                                    letterSpacing: '0.5px',
                                    '&:hover': {
                                        color: "#e10098",
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                {item.label}
                            </Link>
                        ))}
                    </Box>
                </Box>
            )}

            {/* Navbar inferior en móviles */}
            {isMobile && (
                <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    position="fixed"
                    bottom="0"
                    width="100%"
                    height="60px"
                    backgroundColor="#fff"
                    boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
                    zIndex="2"
                >
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <IconButton
                            onClick={() => handleNavigation("Vestidos", "/?cat=Vestidos")}
                            sx={{ color: currentCategory === "Vestidos" ? "#e10098" : "#000" }}
                        >
                            <CheckroomIcon />
                        </IconButton>
                        <Typography
                            variant="caption"
                            sx={{ color: currentCategory === "Vestidos" ? "#e10098" : "#000" }}
                        >
                            Vestidos
                        </Typography>
                    </Box>
                    {/*
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <IconButton
                            onClick={() => handleNavigation("Accesorios", "/?cat=Accesorios")}
                            sx={{ color: currentCategory === "Accesorios" ? "#e10098" : "#000" }}
                        >
                            <ShoppingBagOutlined />
                        </IconButton>
                        <Typography
                            variant="caption"
                            sx={{ color: currentCategory === "Accesorios" ? "#e10098" : "#000" }}
                        >
                            Accesorios
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <IconButton
                            onClick={() => handleNavigation("Calzado", "/?cat=Calzado")}
                            sx={{ color: currentCategory === "Calzado" ? "#e10098" : "#000" }}
                        >
                            <DirectionsWalkIcon />
                        </IconButton>
                        <Typography
                            variant="caption"
                            sx={{ color: currentCategory === "Calzado" ? "#e10098" : "#000" }}
                        >
                            Calzado
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <IconButton
                            onClick={() => handleNavigation("Faqs", "/faq")}
                            sx={{ color: currentCategory === "Faqs" ? "#e10098" : "#000" }}
                        >
                            <HelpOutlineOutlined />
                        </IconButton>
                        <Typography
                            variant="caption"
                            sx={{ color: currentCategory === "Faqs" ? "#e10098" : "#000" }}
                        >
                            Ayuda
                        </Typography>
                    </Box>
                    */}

                </Box>
            )}
        </>
    );
};

export default Navbar;
