import { useState, useEffect } from 'react';

const useFetchSizes = (productId) => {
  const [sizes, setSizes] = useState([]);
  const [loadingSizes, setLoading] = useState(true);
  const [errorSizes, setError] = useState(null);

  useEffect(() => {
    if (!productId) return; // No hacer la petición si no hay un productId

    const fetchSizes = async () => {
      try {
        const response = await fetch(`https://api.ternurareal.mx/api/inventarios?filters[producto][id][$eq]=${productId}&populate=talla`);
        const data = await response.json();

        // Procesar los datos obtenidos
        const availableSizes = data.data.map(item => ({
          idInventario: item.id,
          idTalla: item.attributes.talla.data.id,
          talla: item.attributes.talla.data.attributes.talla,
          existencia: item.attributes.existencia,
          descuentoPorcentaje: item.attributes.descuentoPorcentaje,
          cantidadEstimada: item.attributes.cantidadEstimada,
        }));

        setSizes(availableSizes);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSizes();
  }, [productId]); // Vuelve a hacer la petición si cambia el productId

  return { sizes, loadingSizes, errorSizes };
};

export default useFetchSizes;
